import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, json } from "react-router-dom";
import {
  Row,
  DropdownButton,
  Button,
  Dropdown,
  Container,
  Modal,
  Nav,
  Col,
} from "react-bootstrap";
import axios from "axios";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import { SpinnerCustom } from "../Spinner";
import ReactCountryFlag from "react-country-flag";

import LeagueNav from "../LeagueSideBar";
import Team_image from "../../images/team_images.json";
import "../../css/Navigation.css";
//import https from "https";
import LgHeaderGoals from "../ChartComponents/LeagueHeaderChart";
import LgHeaderCards from "../ChartComponents/LeagueHeaderChatCards";
import LgHeaderCorners from "../ChartComponents/LeagueHeaderCorners";
import SummaryWinners from "../ChartComponents/SummaryWinners";
import LeagueStatisticsDisplay from "./LeagueStatsStatistics";
import LeagueTablesMain from "./League_Tables_Main";
import league_images from "../../images/league_images.json";
import LeagueTablesMainSmall from "./LeagueTableSimple";
import LeagueFixtures from "./LeauesFeatures";
import FooterAll from "../Footer";
import FooterNav from "../SmallScreenNav";
import "../../css/LeagueStats.css";
import { Errorlog, SideNavPage } from "../Utils";
import ShowTicket from "../ShowTicketGames";
import IndexSubheads from "../IndexSubheader";
import { CatchedStoraged } from "../manageStorage/CatchedData";

const API_URL = process.env.REACT_APP_API_URL;

function LeagueStats(params) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [loading, setLoading] = useState(true);

  const [useFixture, setFixture] = useState();
  const [useLeague, setUseLeague] = useState();
  const navigation = useNavigate();

  const [activeElementm, setActiveElementm] = useState(0);
  const [useLeagueStatistics, setUseLeagueStatistics] = useState();

  const [useTable, setUSeTable] = useState(false);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const location = useLocation();
  //const lagueNavData = location.state?.data || " ";

  const countryName = location.state?.ctry || " ";
  const leagueID = String(location.state?.l_id) || " ";
  const leagueName = location.state?.l_n || " ";

  /* const urlParams = new URLSearchParams(window.location.search);
  //s////console.log(urlParams)
  const countryName = urlParams.get("ctry") || " ";
  const leagueID = String(urlParams.get("l_id")) || " ";
  const leagueName = urlParams.get("l_n") || " "; */

  const [useLeagueID, setUseLeagueID] = useState(leagueID);
  const [lagueNavData, setuselagueNavData] = useState();
  const [useHeaderHalf, setUserHeaderHalf] = useState("Full Time");
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [Shows, setShows] = useState(false);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
  };

  const [showFirstSbar, setshowFirstSbar] = useState({
    target: "SUMMARY",
    status: true,
  });
  useEffect(() => {
    const fetchData2 = async () => {
      let ckUser;
      if (!globalUser.user) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
      }
      if (ckUser && ckUser.result === "retrieved") {
        updateGlobalUser(ckUser?.data?.user);
        if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "inactive"
        ) {
          setIsSubscribed(false);
        } else if (
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.subscription &&
          ckUser?.data?.user?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
          setIsLogedin(false);
        }
        try {
          axios.post(`${API_URL}/active_users_save`, {
            email: ckUser?.data?.user?.user?.email,
            page: "statistics_ranking",
          });
        } catch (error) {
          ("do noithing");
        }
      } else {
        if (
          (globalUser &&
            globalUser?.user?.firstname === "Guest" &&
            globalUser?.user) ||
          (globalUser && Object.keys(globalUser).length === 0)
        ) {
          setIsLogedin(false);
          setIsSubscribed(false);
        } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
          if (
            globalUser &&
            !globalUser?.user?.subscription &&
            globalUser?.user
          ) {
            ////console.log(globalUser);
            setIsLogedin(true);
            setIsSubscribed(false);
          } else {
            if (
              globalUser &&
              globalUser?.user?.subscription?.status === "inactive"
            ) {
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              setIsLogedin(true);
              setIsSubscribed(true);
            }
          }
        }
      }
      //***removing supscription****
      setIsSubscribed(true)
    };

    fetchData2();
  }, []);

  ////console.log(leagueID, globalUser);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    //setFixture(() => {});
    if (
      leagueID !== null &&
      globalUser.user &&
      globalUser.user.dataMode &&
      globalUser.user.dataMode === "low"
    ) {
      const get_lgdata = async () => {
        let res = {};
        let options = {
          body: JSON.stringify({
            country: countryName,
            league_name: leagueName,
            league_id: useLeagueID,
            mode: "low",
          }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "max-age=" + 2 * 60 * 60,
          },

          seconds: 2 * 60 * 60,
        };
        let url = "/league_headers";
        let indexdb = "LeagueHeaders";
        let SaveKey = "" + useLeagueID + leagueName;
        
        try {
          const res = await CatchedStoraged(url, options, SaveKey, indexdb);
          ////console.log(res);
          /* const res = await axios.post(
            `${API_URL}/league_headers`,
            JSON.stringify({
              country: countryName,
              league_name: leagueName,
              league_id: useLeagueID,
              mode: "low",
            }),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ); */
          const fix_data = res;
          ////console.log(res);
          ////console.log("loaded fetchdata", fix_data?.status);
          setFixture(JSON.parse(fix_data?.data));
          setLoading(false);
          setUseLeague({ header: JSON.parse(fix_data?.data) });
          setuselagueNavData(JSON.parse(fix_data?.league_nav));
          ////console.log(JSON.parse(fix_data?.league_nav))
          if (
            ![
              "UEFA",
              "CUP",
              "uefa",
              "EUROPA",
              "Copa",
              "COPA",
              "Coupe",
              "copa",
              "cup",
              "Cup",
              "Europe",
              "Uefa",
            ].some((l_type) => leagueName.split(" ").includes(l_type))
          ) {
            setUSeTable(false);
          } else {
            setUSeTable(true);
          }
        } catch (error) {
          Errorlog(
            "loaderror",
            "error loading statistics",
            "from league stats"
          );
        }
      };
      get_lgdata();
    }
  }, [leagueID, useLeagueID, globalUser.user && globalUser.user.dataMode]);

  const HeaderChartHalves = {
    "Full Time": "ft",
    "Half Time": "ht",
    "second Half": "2ht",
  };
  const showFirstSbar_ = ({ target }) => {
    setshowFirstSbar(target);
    ////console.log("reset showfirstbar", target);
  };
  function BreadLinks() {
    /* const leagues = useFixture?.data?.team_info?.league_info;
    const league = GetLeagueName(leagues, lg); */
    ////console.log(league, lg);
    const team_navs = [
      useLeague?.header?.league_meta?.country,
      useLeague?.header?.league_meta?.league_name,
    ];
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        {window.innerWidth < 1000 ? (
          <div className=" left_div smallFont center_sides">
            {team_navs.map((item, index) => (
              <div key={index} className="breadNav_light">
                <div className="tiny_font nowrap">
                  {item}
                  {"  >"}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Container className="justify-center">
            <div className=" left_div smallFont center_sides">
              {team_navs.map((item, index) => (
                <div key={index} className="breadNav_light">
                  {item}
                  {"  >"}
                </div>
              ))}
            </div>
          </Container>
        )}
      </div>
    );
  }

  function FooterNav2(params) {
    // this  handles the fotter of the modal display
    //const team_navs1 = ["SUMMARY", "STATISTICS", "PREDICTIONS", "PLAYERS"];
    const team_navs1 = ["SUMMARY", "STATISTICS", "STANDINGS", "FIXTURES"];
    const team_navs2 = ["STANDINGS", "FIXTURES"];
    let team_navs;
    if (useTable === true) {
      team_navs = team_navs2;
    } else {
      team_navs = team_navs1;
    }
    const handleNavClickm = (id) => {
      setActiveElementm(id);
    };
    return (
      <div >
        {window.innerWidth < 1000 ? (
          <div>
            <hr />
            <div
              className=" left_div  center_sides nav_scroller noyscrol"
              style={{
                marginTop: "-12px",
              }}
            >
              {team_navs.map((item, index) => {
                if (item === "SUMMARY") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElementm === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClickm(index);
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                } else if (item === "STANDINGS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElementm === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClickm(index);
                      }}
                    >
                      <div className="tiny_font">{item}</div>
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className={`breadNav_light ${
                      activeElementm === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      showFirstSbar_({
                        target: { target: item, status: true },
                      });
                      handleNavClickm(index);
                    }}
                  >
                    <div className="tiny_font">{item}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Container className="justify-center">
            <hr />
            <div
              className="left_div  center_sides nav_scroller noyscrol"
              style={{
                marginTop: "-12px",
              }}
            >
              {team_navs.map((item, index) => {
                if (item === "SUMMARY") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElementm === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: false },
                        });
                        handleNavClickm(index);
                      }}
                    >
                      {item}
                    </div>
                  );
                } else if (item === "STANDINGS") {
                  return (
                    <div
                      key={index}
                      className={`breadNav_light ${
                        activeElementm === index
                          ? "div_active link forceblack"
                          : "dormant link forceblack"
                      }`}
                      onClick={() => {
                        showFirstSbar_({
                          target: { target: item, status: true },
                        });
                        handleNavClickm(index);
                      }}
                    >
                      {item}
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    className={`breadNav_light ${
                      activeElementm === index
                        ? "div_active link forceblack"
                        : "dormant link forceblack"
                    }`}
                    onClick={() => {
                      showFirstSbar_({
                        target: { target: item, status: true },
                      });
                      handleNavClickm(index);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </Container>
        )}
      </div>
    );
  }
  function TeamHead() {
    // this displays the main modal head of the teams

    const HandleYearClick = (item) => {
      setUseLeagueID(item?.id);
    };
    const country = useFixture?.head_data?.team_country;
    ////console.log(useLeague, useFixture, useLeagueID);
    if (!useLeague || !useFixture || !useLeagueID) {
      return (
        <SpinnerCustom myImage={league_images?.[String(leagueID)]?.image} />
      );
    }
    const leag_ = useLeague?.header?.seasons;
    //console.log(useLeague, leagueID, useFixture);
    let curr_league = {};
    for (let index = 0; index < leag_?.length; index++) {
      const element = leag_[index];
      if (parseInt(element?.id) === parseInt(useLeagueID)) {
        curr_league = element;
      }
    }
    return (
      <div className="manFont">
        <Modal.Dialog>
          <BreadLinks />

          <Modal.Body>
            {window.innerWidth < 1200 ? (
              <>
                <div className="usemaxSpace">
                  {isSmallScreen ? (
                    <>
                      <div className=" center_straight center_sides alligh_center">
                        <div className="allpad">
                          <img
                            src={useLeague?.header?.league_meta?.image}
                            alt={useLeague?.header?.league_meta?.league_name}
                            style={{
                              width: "100px", // or any value appropriate for your design
                              height: "100px", // keep aspect ratio if not defined
                            }}
                          />
                        </div>
                        <div className="mainFont tiny_font">
                          <div className="mainFont bold  ">
                            {useLeague?.header?.league_meta?.league_name}
                          </div>
                          <div className="left_div center_sides">
                            <div className="allpad">
                              <ReactCountryFlag
                                countryCode={
                                  lagueNavData?.country_info?.[
                                    useLeague?.header?.league_meta?.country
                                  ]?.iso
                                }
                                svg
                                style={{
                                  fontSize: "2em",
                                  lineHeight: "2em",
                                }}
                                aria-label={country}
                              />
                            </div>
                            <div className="bold">
                              {useLeague?.header?.league_meta?.country}
                            </div>
                          </div>{" "}
                          <div className="center_sides left_div ">
                            <div className="allpad">Season :</div>
                            <div className="">
                              <DropdownButton
                                title={
                                  String(curr_league?.["year"]).slice(0, 4) +
                                  "/" +
                                  String(curr_league?.["year"]).slice(4)
                                }
                                id="dropdown-basic-button"
                                size="sm"
                                className="small-button_2"
                              >
                                {useLeague?.header?.seasons
                                  .sort((a, b) => {
                                    return (
                                      parseInt(String(b.year).slice(0, 4)) -
                                      parseInt(String(a.year).slice(0, 4))
                                    );
                                  })
                                  .map((item, index) => (
                                    <Dropdown.Item
                                    key={index}
                                      onClick={() => HandleYearClick(item)}
                                    >
                                      <div className="allpad">
                                        {String(item?.["year"]).slice(0, 4) +
                                          "/" +
                                          String(item?.["year"]).slice(4)}
                                      </div>
                                    </Dropdown.Item>
                                  ))}
                              </DropdownButton>
                            </div>
                          </div>
                          <div className="center_sides left_div">
                            <div className="allpad">{"Half :"}</div>
                            <div className="">
                              <DropdownButton
                                title={useHeaderHalf}
                                id="dropdown-basic-button"
                                size="sm"
                                className="small-button_2"
                              >
                                {Object.keys(HeaderChartHalves).map(
                                  (item, index) => (
                                    <Dropdown.Item
                                      onClick={() => setUserHeaderHalf(item)}
                                    >
                                      {item}
                                    </Dropdown.Item>
                                  )
                                )}
                              </DropdownButton>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="center_div_distributed center_sides nav_scroller removespave-40top">
                        <div className="center_div">
                          <LgHeaderGoals
                            goals={useLeague?.header?.goals_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                        <div className="center_div">
                          <LgHeaderCards
                            Cards={useLeague?.header?.cards_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                        <div className="center_div padleft">
                          <LgHeaderCorners
                            Corners={useLeague?.header?.corners_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                      </div>
                    </>
                  ) : window.innerWidth < 1200 ? (
                    <>
                      <div className="left_div  center_sides removespave-30top">
                        <div className="center_sides left_div">
                          <div className="allpad">
                            <img
                              src={useLeague?.header?.league_meta?.image}
                              alt={useLeague?.header?.league_meta?.league_name}
                              style={{
                                width: "110px", // or any value appropriate for your design
                                height: "110px", // keep aspect ratio if not defined
                              }}
                            />
                          </div>
                          <div className="mainFont tiny_font">
                            <div className="mainFont bold  ">
                              {useLeague?.header?.league_meta?.league_name}
                            </div>
                            <div className="left_div center_sides">
                              <div className="allpad">
                                <ReactCountryFlag
                                  countryCode={
                                    lagueNavData?.country_info?.[
                                      useLeague?.header?.league_meta?.country
                                    ]?.iso
                                  }
                                  svg
                                  style={{
                                    fontSize: "2em",
                                    lineHeight: "2em",
                                  }}
                                  aria-label={country}
                                />
                              </div>
                              <div className="bold">
                                {useLeague?.header?.league_meta?.country}
                              </div>
                            </div>{" "}
                            <div className="center_sides left_div ">
                              <div className="allpad">Season :</div>
                              <div className="">
                                <DropdownButton
                                  title={
                                    String(curr_league?.["year"]).slice(0, 4) +
                                    "/" +
                                    String(curr_league?.["year"]).slice(4)
                                  }
                                  id="dropdown-basic-button"
                                  size="sm"
                                  className="small-button_2"
                                >
                                  {useLeague?.header?.seasons
                                    .sort((a, b) => {
                                      return (
                                        parseInt(String(b.year).slice(0, 4)) -
                                        parseInt(String(a.year).slice(0, 4))
                                      );
                                    })
                                    .map((item, index) => (
                                      <Dropdown.Item
                                        onClick={() => HandleYearClick(item)}
                                      >
                                        <div className="allpad">
                                          {String(item?.["year"]).slice(0, 4) +
                                            "/" +
                                            String(item?.["year"]).slice(4)}
                                        </div>
                                      </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                              </div>
                            </div>
                            <div className="center_sides left_div">
                              <div className="allpad">{"Half :"}</div>
                              <div className="">
                                <DropdownButton
                                  title={useHeaderHalf}
                                  id="dropdown-basic-button"
                                  size="sm"
                                  className="small-button_2"
                                >
                                  {Object.keys(HeaderChartHalves).map(
                                    (item, index) => (
                                      <Dropdown.Item
                                        onClick={() => setUserHeaderHalf(item)}
                                      >
                                        {item}
                                      </Dropdown.Item>
                                    )
                                  )}
                                </DropdownButton>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                          <div className="center_straight center_sides">
                            <div>
                              <LgHeaderGoals
                                goals={useLeague?.header?.goals_dict}
                                type={HeaderChartHalves?.[useHeaderHalf]}
                              />
                            </div>
                            <div>
                              <LgHeaderCards
                                Cards={useLeague?.header?.cards_dict}
                                type={HeaderChartHalves?.[useHeaderHalf]}
                              />
                            </div>
                            <div>
                              <LgHeaderCorners
                                Corners={useLeague?.header?.corners_dict}
                                type={HeaderChartHalves?.[useHeaderHalf]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <Container>
                  <div className=" left_div center_sides">
                    <div >
                      <Container>
                        <img
                          src={useLeague?.header?.league_meta?.image}
                          alt={useLeague?.header?.league_meta?.league_name}
                          style={{
                            width: "200px", // or any value appropriate for your design
                            height: "200px", // keep aspect ratio if not defined
                          }}
                        />
                      </Container>
                    </div>
                    <div  className="mainFont">
                      <div>
                        <div className="mainFont bold paragrph_font">
                          {useLeague?.header?.league_meta?.league_name}
                        </div>
                      </div>
                      <div className="left_div center_sides">
                        <div>
                          <ReactCountryFlag
                            countryCode={
                              lagueNavData?.country_info?.[
                                useLeague?.header?.league_meta?.country
                              ]?.iso
                            }
                            svg
                            style={{
                              fontSize: "2em",
                              lineHeight: "2em",
                            }}
                            aria-label={country}
                          />
                        </div>
                        <div className="bold">
                          {useLeague?.header?.league_meta?.country}
                        </div>
                      </div>{" "}
                      
                        <div className="left_div center_sides">
                          <div  className="allpad">
                            Season :
                          </div>
                          <div  className="">
                            <DropdownButton
                              title={
                                String(curr_league?.["year"]).slice(0, 4) +
                                "/" +
                                String(curr_league?.["year"]).slice(4)
                              }
                              id="dropdown-basic-button"
                              size="sm"
                              className="small-button_2"
                            >
                              {useLeague?.header?.seasons
                                .sort((a, b) => {
                                  return (
                                    parseInt(String(b.year).slice(0, 4)) -
                                    parseInt(String(a.year).slice(0, 4))
                                  );
                                })
                                .map((item, index) => (
                                  <Dropdown.Item
                                    onClick={() => HandleYearClick(item)}
                                  >
                                    {String(item?.["year"]).slice(0, 4) +
                                      "/" +
                                      String(item?.["year"]).slice(4)}
                                  </Dropdown.Item>
                                ))}
                            </DropdownButton>
                          </div>
                        </div>
                      
                     
                        <div className="left_div center_sides">
                          <div className="allpad">
                            {"Half :"}
                          </div>
                          <div className="">
                            <DropdownButton
                              title={useHeaderHalf}
                              id="dropdown-basic-button"
                              size="sm"
                              className="small-button_2"
                            >
                              {Object.keys(HeaderChartHalves).map(
                                (item, index) => (
                                  <Dropdown.Item
                                    onClick={() => setUserHeaderHalf(item)}
                                  >
                                    {item}
                                  </Dropdown.Item>
                                )
                              )}
                            </DropdownButton>
                          </div>
                        </div>
                   
                    </div>
                    <div  className="usemaxSpace">
                      <div className="center_div_distributed center_sides">
                        <div className="">
                          <LgHeaderGoals
                            goals={useLeague?.header?.goals_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                        <div className="">
                          <LgHeaderCards
                            Cards={useLeague?.header?.cards_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                        <div  className="">
                          <LgHeaderCorners
                            Corners={useLeague?.header?.corners_dict}
                            type={HeaderChartHalves?.[useHeaderHalf]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </>
            )}
          </Modal.Body>
          <FooterNav2 />
        </Modal.Dialog>
      </div>
    );
  }
  //f
  ////console.log(showFirstSbar?.target);
  ////console.log(useLeague, useFixture, useLeagueID);
  if (!useLeague || !useFixture || !useLeagueID) {
    return <SpinnerCustom myImage={league_images?.[String(leagueID)]?.image} />;
  }
  function RouteStats({ Links_ }) {
    if (Links_ && Links_ === "PAST GAMES" && useTable === false) {
      //return <DisplayPastGames Data={useFixture} Tab={statsToShow} />;
    }
    if (Links_ && Links_ === "SUMMARY" && useTable === false) {
      ////console.log(useLeague?.SUMMARY);
      return <SummaryWinners league={useLeagueID} />;
    }
    if (Links_ && Links_ === "STATISTICS" && useTable === false) {
      ////console.log(useLeagueStatistics);
      //console.log(useTable)
      return (
        <div>
          {!issubscribed ? (
            <div>
              <div className="margin30down mainFont">
                <div className="">
                  <div className="center_div infoFont margin-30down">
                    Subscribe to get access to our Advanced Statistics
                  </div>
                  <div className="center_div infoFont margin-30down">
                    We have done all the calculations so you do not have to
                  </div>
                  <div
                    className="link smallbuton  divorangeButton"
                    onClick={() => navigation("/pricing")}
                  >
                    Subscribe
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <LeagueStatisticsDisplay
                data={useLeagueStatistics}
                league={useLeagueID}
                small={isSmallScreen}
                mid={isMidScreen}
              />
            </>
          )}
        </div>
      );
    }
    //console.log(useTable, Links_)
    if (Links_ && Links_ === "STANDINGS" && useTable === false) {
      
      return (
        <div>
          <LeagueTablesMainSmall
            askleague={useLeagueID}
            small={isSmallScreen}
            mid={isMidScreen}
          />
        </div>
      );
    }
    if (Links_ && Links_ === "FIXTURES" ) {
      
      return (
        <div>
          <LeagueFixtures
            askleague={useLeagueID}
            small={isSmallScreen}
            mid={isMidScreen}
          />
        </div>
      );
    }
    if (useTable === true) {
      return (
        <LeagueTablesMain
          League_list={useFixture?.[useLeagueID]?.table}
          askleague={useLeagueID}
          setaskleague={setUseLeagueID}
          league_info_list={useFixture?.seasons}
          asking_team={"none"}
          raw_league={""}
          small={isSmallScreen}
          mid={isMidScreen}
        />
      );
    }
  }
  ////console.log(loading, useFixture);

  return (
    <div className="light">
    {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen}/>}
      <IndexHeader
        radioValue={radioValue}
        setRadioValue={setRadioValue}
        loading={loading}
        setisclickTicket={setisclickTicket}
        isclickTicket={isclickTicket}
        isLogedin={isLogedin}
        issubscribed={issubscribed}
        isOpen={isOpen} 
        setIsOpen={setIsOpen}
      />
      {isMidScreen || isSmallScreen ? (
        <IndexSubheads
          sidenavOpen={sidenavOpen}
          setSidenavOpen={setSidenavOpen}
          islgedin={isLogedin}
          issubed={issubscribed}
          setticketactive={setticketactive}
          setShows={setShows}
          type={"league"}
          setleague={setUseLeagueID}
        />
      ) : null}
      {isMidScreen || isSmallScreen ? (
        <>
          {window.innerWidth < 10000 ? (
            <div className="allpad">
              {/* <div>
                <div>
                  <div className="allpad">
                    <Button
                      className="webButton margin10down"
                      onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                    >
                      Top Leagues
                    </Button>
                  </div>
                </div>
              </div> */}
              {(isDropdownVisible && isMidScreen) ||
              (isDropdownVisible && isSmallScreen) ? (
                <div>
                  <div md="6" sm="11" className="usemaxSpace center_div">
                    <div className="allpad">
                      <Container className="cont_ad">
                        <LeagueNav
                          setleague={setUseLeagueID}
                          islgedin={isLogedin}
                          issubed={issubscribed}
                          setticketactive={setticketactive}
                          setShows={setShows}
                        />
                      </Container>
                    </div>
                  </div>
                </div>
              ) : null}

              <div>
                <div>
                  <div className="usemaxSpace">
                    <div className={`$"light"`}>
                      {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                      <div className="margin-5down">
                        {loading ? <Spinner /> : useFixture && <TeamHead />}
                      </div>
                      <div>
                        {(useTable === false &&
                          showFirstSbar?.status === true && (
                            <RouteStats Links_={showFirstSbar?.target} />
                          )) ||
                          (useTable === false &&
                            showFirstSbar?.target === "SUMMARY" && (
                              <RouteStats Links_={showFirstSbar?.target} />
                            ))}
                      </div>
                      <div>
                        {useTable === true && (
                          <RouteStats Links_={showFirstSbar?.target} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <Container>
                {/* <div>
                  <div>
                    <div className="allpad">
                      <Button
                        className="webButton margin10down"
                        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                      >
                        Top Leagues
                      </Button>
                    </div>
                  </div>
                </div> */}
                {(isDropdownVisible && isMidScreen) ||
                (isDropdownVisible && isSmallScreen) ? (
                  <div>
                    <div md="6" sm="11" className="usemaxSpace center_div">
                      <div className="allpad">
                        <Container className="cont_ad">
                          <LeagueNav
                            setleague={setUseLeagueID}
                            islgedin={isLogedin}
                            issubed={issubscribed}
                            setticketactive={setticketactive}
                            setShows={setShows}
                          />
                        </Container>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div>
                  <div>
                    <Container className="usemaxSpace">
                      <div className={`$"light"`}>
                        {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                        <Container className="margin-5down">
                          {/* <TeamHead /> */}

                          {loading ? <Spinner /> : useFixture && <TeamHead />}
                        </Container>
                        <Container>
                          {(useTable === false &&
                            showFirstSbar?.status === true && (
                              <RouteStats Links_={showFirstSbar?.target} />
                            )) ||
                            (useTable === false &&
                              showFirstSbar?.target === "SUMMARY" && (
                                <RouteStats Links_={showFirstSbar?.target} />
                              ))}
                        </Container>
                        <Container>
                          {useTable === true && (
                            <RouteStats Links_={showFirstSbar?.target} />
                          )}
                        </Container>
                      </div>
                    </Container>
                  </div>
                </div>
              </Container>
            </>
          )}
        </>
      ) : (
        <>
          <Container>
            <div className="flex center_sides">
              <div style={{width:"20%" }}>
                <div
                >
                  
                    <LeagueNav
                      setleague={setUseLeagueID}
                      islgedin={isLogedin}
                      issubed={issubscribed}
                    />
                 
                </div>
              </div>
              <div >
                <Container className="usemaxSpace">
                  <div className={`$"light"`}>
                    {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows}/>
                        </div>
                      )} */}
                    <div className="margin-5down">
                      {/* <TeamHead /> */}
                      {loading ? <Spinner /> : useFixture && <TeamHead />}
                    </div>
                    <div>
                      {(useTable === false &&
                        showFirstSbar?.status === true && (
                          <RouteStats Links_={showFirstSbar?.target} />
                        )) ||
                        (useTable === false &&
                          showFirstSbar?.target === "SUMMARY" && (
                            <RouteStats Links_={showFirstSbar?.target} />
                          ))}
                    </div>
                    <Container>
                      {useTable === true && (
                        <RouteStats Links_={showFirstSbar?.target} />
                      )}
                    </Container>
                  </div>
                </Container>
              </div>
            </div>
          </Container>
        </>
      )}

      <FooterAll />
      <FooterNav />
    </div>
  );
}
export default LeagueStats;
