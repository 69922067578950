import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Table,
  Row,
  NavLink,
  Button,
  Container,
  Modal,
  Nav,
  Col,
  Stack,
  Alert,
} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";

import Team_image from "../../images/team_images.json";
import { CallError, Errorlog, convertUnixToTime } from "../Utils";
import { IoMdAddCircle } from "react-icons/io";
import { MdOutlineReceipt } from "react-icons/md";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import "../../css/Teamstats.css";
import { TiCancel } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import { MdOutlinePending } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import Spinner from "../Spinner";
import CollectOptions from "../CollectMatchOptions";
import { MDBPopover, MDBPopoverBody, MDBPopoverHeader } from "mdb-react-ui-kit";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;
function getColorCode(value) {
  const valueNormalized = value / 100; // Normalize value to range 0-1
  // Hue range for green to red (reversed)
  const hue = (valueNormalized * 120) / 360.0;
  const lightness = 0.5; // Fixed lightness for all colors
  const saturation = 0.8; // Fixed saturation for all colors

  const rgbColor = hlsToRgb(hue, lightness, saturation).map((x) =>
    Math.round(255 * x)
  );

  function rgbToHex(rgb) {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  return rgbToHex(rgbColor);
}

// Helper function to convert HLS to RGB
function hlsToRgb(h, l, s) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r, g, b];
}
/* function convertUnixToTime(unixTime) {
  // Create a new Date object using the unixTime (in milliseconds)
  const date = new Date(unixTime * 1000);

  const options = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
} */

function GetPercentatge(value, strings) {
  let tot = "";
  let values = "";
  if (typeof strings === "string") {
    tot = strings.split("");
    values = value / tot.length;
  } else {
    values = value / strings;
  }

  const semval = Math.round(values * 100);
  let out = `${semval} %`;
  if (out === "NaN %") {
    out = "- %";
  }
  return out;
}
function Getcolor(value, strings) {
  let tot = "";
  let values = "";
  if (typeof strings === "string") {
    tot = strings.split("");
    values = value / tot.length;
  } else {
    values = value / strings;
  }

  const semval = Math.round(values * 100);
  const out = getColorCode(semval);
  return out;
}
const GetHalfWin = (homeID, awayID, homeVal, awayVal) => {
  if (homeVal > awayVal) {
    return homeID;
  } else if (homeVal < awayVal) {
    return awayID;
  } else {
    return -1;
  }
};
///Code Start here
export default function DisplayRanking({
  Match,
  Tab2,
  date,
  Top,
  half,
  opt3,
  opt4,
  small,
  mid,
  isGosClose,
}) {
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [monthCount, setMonthCount] = useState(10);
  const [Shows, setShows] = useState(false);
  const [loading, setloading] = useState(true);
  const [Data, setData] = useState({});
  const [haveTicket, sethaveTicket] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [isClickIndex, setisClickIndex] = useState();
  const [isStarted, setIsStarted] = useState();
  const [basicWarning, setBasicWarning] = useState();
  const [isPopOpen, setisPopOpen] = useState(true);
  const [isTicketError, setIsTicketError] = useState(false);
  useEffect(() => {
    const get_mdata = async () => {
      let options = {
        body: JSON.stringify({
          date: date,
          op1: Top,
          op2: Tab2,
          op3: half,
          op4: opt3,
          op5: opt4,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 2 * 60,
        },

        seconds: 2 * 60 ,
      };
      let url = "/RankingStatistics";
      let indexdb = "RankingStatistics";
      let SaveKey = "" + date + Top + Tab2 + half + opt3 + opt4;
      console.log(""+date + Top + Tab2 + half + opt3 + opt4)
      try {
        const res = await CatchedStoraged(url, options, SaveKey, indexdb);
        /* const res = await axios.post(
          `${API_URL}/RankingStatistics`,
          JSON.stringify({
            date: date,
            op1: Top,
            op2: Tab2,
            op3: half,
            op4: opt3,
            op5: opt4,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ); */
        const fix_data = JSON.parse(res?.data);
        ////console.log(fix_data);
        console.log(res)
        if (res?.status === "success") {
          setData(fix_data?.data);

          setloading(false);
        } else {
          Errorlog(
            "loaderror",
            "error ranking statistics",
            "from ranking stats"
          );
        }
      } catch (error) {
        Errorlog(error, "error ranking statistics", "from display rank stats");
        ////console.log(error);
      }
    };
    get_mdata();
  }, [date, Top, Tab2, half, opt3, opt4]);
  ////console.log(Data_)
  if (!Data || loading || !half) {
    return <Spinner />;
  }

  ////console.log(small);
  function MultiLeague({ monthCount }) {
    const navigation = useNavigate();
    const DisplayData = ({ Indata_, index, handleDropclick }) => {
      let halfs = "ft";
      if (half === "Full Time") {
        halfs = "ft";
      } else if (half === "First Half") {
        halfs = "ht";
      } else if (half === "Second Half") {
        halfs = "2h";
      }
      let ownID = "";
      let oppID = "";

      ownID = [Indata_?.homeID];
      oppID = [Indata_?.awayID];

      const Indata = Indata_;
      ////console.log(Indata, Indata_, ownID);

      ////console.log(Indata);
      const handleteamclick = ({ val, team_, league }) => {
        const team = val;
        navigation("/team/" + team_, { state: { team, league } });
      };

      let gtype_ = "";
      let gt2 = "";
      if (
        Tab2 !== "Win-Draw-Lose" ||
        Tab2 !== "WDL-Cards" ||
        Tab2 !== "WDL-Corners"
      ) {
        if (opt3 === "Over Goals " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Goals " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }
      if (Tab2 === "Cards") {
        if (opt3 === "Over Cards " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Cards " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }
      if (Tab2 === "Corners") {
        if (opt3 === "Over Corners " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Corners " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }

      const match_name = `${Math.min(ownID, oppID)}_${Math.max(ownID, oppID)}`;
      ////console.log(match_name);

      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      let gh21 = "";

      let ch21 = "";
      let coh22 = "";

      let ch22 = "";
      let coh23 = "";

      let ch23 = "";

      let coh21 = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
        gh21 = "3.5";

        ch21 = "3.5";

        coh21 = "8.5";
        halfs = "ft";
        if (opt4 !== "Match") {
          ch22 = "1.5";
          gh2 = "1.5";
          gh21 = "2.5";

          coh22 = "4.5";
          ch23 = "2.5";

          coh23 = "5.5";
        }
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "1.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "0.5";

        coh21 = "4.5";
        halfs = "ht";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "1.5";

        coh21 = "4.5";
        halfs = "2h";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      }
      const handlematchlick = (match_id, team1, team2, seas) => {
        navigation("/match/" + match_id, {
          state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
        });
      };
      ////console.log(Indata);
      return (
        <>
          <td className="dates">
            {small
              ? convertUnixToTime(Indata && Indata["date_unix"], "time")
              : convertUnixToTime(Indata && Indata["date_unix"], "")}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td className="awayTable td_ link">
                <div>
                  {Indata?.league_detail && (
                    <ReactCountryFlag
                      countryCode={Indata?.league_detail?.country_iso}
                      svg
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label={Indata?.league_detail?.country}
                    />
                  )}{" "}
                  {Indata?.league_detail?.league_name}
                </div>
              </td>
            </>
          )}

          <td
            className="link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["homeID"],
                team_: Indata?.["home_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            {Indata?.winningTeam === Indata?.homeID ? (
              <div className="right_content bold center_straight ">
                <div
                  className="center_straight center_sides"
                  style={{ maxWidth: window.innerWidth < 770 ? "80px" : "" }}
                >
                  <div
                    className="noBorders"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "right",
                      whiteSpace: "nowrap",
                      wordWrap: "break-word",

                      overflowWrap: "break-word",
                    }}
                  >
                    {Indata?.["home_name"]} &nbsp;
                  </div>
                  <div>
                    <img
                      src={Team_image?.[String(Indata?.["homeID"])]?.["image"]}
                      width="20"
                      height="15"
                      alt={Indata?.["home_name"]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="right_content center_straight ">
                  <div
                    className="center_straight center_sides"
                    style={{ maxWidth: window.innerWidth < 770 ? "80px" : "" }}
                  >
                    <div
                      className="noBorders"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "right",
                        whiteSpace: "nowrap",
                        wordWrap: "break-word",

                        overflowWrap: "break-word",
                      }}
                    >
                      {Indata?.["home_name"]} &nbsp;
                    </div>
                    <div>
                      <img
                        src={
                          Team_image?.[String(Indata?.["homeID"])]?.["image"]
                        }
                        width="20"
                        height="15"
                        alt={Indata?.["home_name"]}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </td>
          <td
            className="formOR"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                width: "20px",
                height: "20px",
                background: Indata_?.header?.[String(ownID)]?.form?.[1],
                marginLeft: "15px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {Indata_?.header?.[String(ownID)]?.form?.[0]}
            </div>
          </td>

          {Indata?.status === "complete" ? (
            <td
              className="statsOR link"
              onClick={() =>
                handlematchlick(
                  Indata && Indata["id"],
                  Indata?.["homeID"],
                  Indata?.["awayID"],
                  Indata?.season
                )
              }
            >
              {halfs === "ft"
                ? Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                  ? `${Indata?.homeGoalCount ?? "-"} : ${
                      Indata?.awayGoalCount ?? "-"
                    }`
                  : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                  ? `${Indata?.team_a_cards_num ?? "-"} : ${
                      Indata?.team_b_cards_num ?? "-"
                    }`
                  : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                  ? `${Indata?.team_a_corners ?? "-"} : ${
                      Indata?.team_b_corners ?? "-"
                    }`
                  : null
                : halfs === "ht"
                ? Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                  ? `${Indata?.ht_goals_team_a ?? "-"} : ${
                      Indata?.ht_goals_team_b ?? "-"
                    }`
                  : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                  ? `${Indata?.team_a_fh_cards ?? "-"} : ${
                      Indata?.team_b_fh_cards ?? "-"
                    }`
                  : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                  ? `${Indata?.team_a_fh_corners ?? "-"} : ${
                      Indata?.team_b_fh_corners ?? "-"
                    }`
                  : null
                : Tab2 === "Win-Draw-Lose" || Tab2 === "Goals"
                ? `${Indata?.goals_2hg_team_a ?? "-"} : ${
                    Indata?.goals_2hg_team_b ?? "-"
                  }`
                : Tab2 === "Cards" || Tab2 === "WDL-Cards"
                ? `${Indata?.team_a_2h_cards ?? "-"} : ${
                    Indata?.team_b_2h_cards ?? "-"
                  }`
                : Tab2 === "Corners" || Tab2 === "WDL-Corners"
                ? `${Indata?.team_a_2h_corners ?? "-"} : ${
                    Indata?.team_b_2h_corners ?? "-"
                  }`
                : null}
            </td>
          ) : (
            <td
              className="statsOR link"
              onClick={() =>
                handlematchlick(
                  Indata && Indata["id"],
                  Indata?.["homeID"],
                  Indata?.["awayID"],
                  Indata?.season
                )
              }
            >
              -:-
            </td>
          )}
          <td
            className="formOR"
            style={{ alignItems: "flex-start", justifyContent: "center" }}
          >
            <div className="left_div  usemaxSpace">
              <div
                style={{
                  display: "flex",
                  width: "20px",
                  height: "20px",
                  background: Indata_?.header?.[String(oppID)]?.form?.[1],
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {Indata_?.header?.[String(oppID)]?.form?.[0]}
              </div>
            </div>
          </td>
          <td
            className="awayOR link"
            onClick={() =>
              handleteamclick({
                val: Indata?.["awayID"],
                team_: Indata?.["away_name"],
                league: Indata?.["competition_id"],
              })
            }
          >
            {Indata?.winningTeam === Indata?.awayID ? (
              <div
                className="center_straight bold center_sides"
                style={{ maxWidth: window.innerWidth < 770 ? "80px" : "" }}
              >
                <div>
                  <img
                    src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                    width="20"
                    height="15"
                    alt={Indata?.["away_name"]}
                  />
                </div>

                <div
                  className="noBorders"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    wordWrap: "break-word",
                    width: "100%",
                    overflowWrap: "break-word",
                  }}
                >
                  &nbsp;
                  {Indata?.["away_name"]}{" "}
                </div>
              </div>
            ) : (
              <>
                <div
                  className="center_straight center_sides"
                  style={{ maxWidth: window.innerWidth < 770 ? "80px" : "" }}
                >
                  <div>
                    <img
                      src={Team_image?.[String(Indata?.["awayID"])]?.["image"]}
                      width="20"
                      height="15"
                      alt={Indata?.["away_name"]}
                    />
                  </div>

                  <div
                    className="noBorders"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      wordWrap: "break-word",
                      width: "100%",
                      overflowWrap: "break-word",
                    }}
                  >
                    &nbsp;
                    {Indata?.["away_name"]}{" "}
                  </div>
                </div>
              </>
            )}
          </td>
          {small ? null : (
            <>
              <td></td>
              <td></td>
            </>
          )}

          {Tab2 === "Win-Draw-Lose" ||
          Tab2 === "WDL-Corners" ||
          Tab2 === "WDL-Cards" ? (
            opt3 === "Home Win" || opt3 === "Away Win" || opt3 === "Draw" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.H?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.H?.score + " %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.D?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.D?.score + " %"}
                    </div>
                  </td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.A?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.A?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td className="predsTable">
                    <div
                      className="center_div link forceblack"
                      onClick={() => handleTicketAdd(Indata, index)}
                    >
                      {Indata?.optns?.result === "pass" ? (
                        <FaCheck size={25} color="green" />
                      ) : Indata?.optns?.result === "fail" ? (
                        <RxCross2 size={25} color="red" />
                      ) : Indata?.optns?.result === "sus" ? (
                        <TiCancel size={25} color="orange" />
                      ) : (
                        <MdOutlinePending size={25} color="grey" />
                      )}
                    </div>
                  </td>
                </>
              )
            ) : opt3 === "Any Team Win" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.Any?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.Any?.score + " %"}
                      </div>
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "HWin EH" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.H_weh?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.H_weh?.score + " %"}
                      </div>
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "HWin EH No" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.H_weh_no?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.H_weh_no?.score + " %"}
                      </div>
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "AWin EH" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.A_weh?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.A_weh?.score + " %"}
                      </div>
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "AWin EH No" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.A_weh_no?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.A_weh_no?.score + " %"}
                      </div>
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "D EH" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.deh?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.deh?.score + " %"}
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt3 === "D EH No" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.deh_no?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.deh_no?.score + " %"}
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : null
          ) : Tab2 === "Goals" ? (
            opt4 === "Match" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.bts?.score + " %"}
                    </div>
                  </td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts_no"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.bts_no?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "BTS Yes" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["bts"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "BTS No" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts_no"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["bts_no"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Home Team Goals" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Away Team Goals" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Home Team To Score" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Away Team To Score" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </td>
                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : null
          ) : Tab2 === "Cards" ? (
            opt4 === "Match" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </td>
                  <td className="preds"></td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Home Team" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </td>
                  <td className="preds"></td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : opt4 === "Away Team" ? (
              small ? (
                <>
                  <td className="link" onClick={() => handleDropclick(index)}>
                    <IoIosArrowDropdownCircle size={20} color="gray" />
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </td>
                  <td className="preds"></td>

                  <td className="preds">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </td>

                  <td className="predsTable">
                    <div className="link usemaxSpace flex ">
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          className="shiftright40"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["home_name"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {Indata?.["away_name"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright40"
                          style={{ minWidth: "380px" }}
                        >
                          <div className="usemaxSpace">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={Indata}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    </div>
                  </td>
                  <td>
                    {Indata?.optns?.result === "pass" ? (
                      <FaCheck size={25} color="green" />
                    ) : Indata?.optns?.result === "fail" ? (
                      <RxCross2 size={25} color="red" />
                    ) : Indata?.optns?.result === "sus" ? (
                      <TiCancel size={25} color="orange" />
                    ) : (
                      <MdOutlinePending size={25} color="grey" />
                    )}
                  </td>
                </>
              )
            ) : null
          ) : opt4 === "Match" ? (
            small ? (
              <>
                <td className="link" onClick={() => handleDropclick(index)}>
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            ) : (
              <>
                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov2.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov2.5"]?.score + " %"}
                  </div>
                </td>

                <td></td>

                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov3.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov3.5"]?.score + " %"}
                  </div>
                </td>
                <td className="predsTable">
                  <div className="link usemaxSpace flex ">
                    <MDBPopover
                      color="grey"
                      className="center_div center_straight usemaxSpace popover"
                      btnChildren={
                        <div
                          style={{
                            border: "none",
                            maxWidth: "12px",
                            maxHeight: "12px",
                          }}
                        >
                          <IoMdAddCircle
                            className="center_div"
                            style={{ marginLeft: "-5px", marginTop: "-7px" }}
                            size={20}
                            color="orange"
                          />
                        </div>
                      }
                      placement="left"
                    >
                      <MDBPopoverHeader
                        className="shiftright40"
                        style={{ minWidth: "460px" }}
                      >
                        <div className="center_div_distributed center_sides allpad usemaxSpace">
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["home_name"]}
                              </div>
                            </div>
                          </div>
                          <div> vs </div>
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["away_name"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MDBPopoverHeader>
                      <MDBPopoverBody
                        className="backgroundwhite shiftright40"
                        style={{ minWidth: "380px" }}
                      >
                        <div className="usemaxSpace">
                          <CollectOptions
                            handleTicketAdd={handleTicketAdd}
                            data={Indata}
                            index={index}
                            isStarted={isStarted}
                            isTicketError={isTicketError}
                            setisPopOpen={setisPopOpen}
                            setIsStarted={setIsStarted}
                            setIsTicketError={setIsTicketError}
                          />
                        </div>
                      </MDBPopoverBody>
                    </MDBPopover>
                  </div>
                </td>
                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            )
          ) : opt4 === "Home Team" ? (
            small ? (
              <>
                <td className="link" onClick={() => handleDropclick(index)}>
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            ) : (
              <>
                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov2.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov2.5"]?.score + " %"}
                  </div>
                </td>

                <td></td>

                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov3.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov3.5"]?.score + " %"}
                  </div>
                </td>
                <td className="predsTable">
                  <div className="link usemaxSpace flex ">
                    <MDBPopover
                      color="grey"
                      className="center_div center_straight usemaxSpace popover"
                      btnChildren={
                        <div
                          style={{
                            border: "none",
                            maxWidth: "12px",
                            maxHeight: "12px",
                          }}
                        >
                          <IoMdAddCircle
                            className="center_div"
                            style={{ marginLeft: "-5px", marginTop: "-7px" }}
                            size={20}
                            color="orange"
                          />
                        </div>
                      }
                      placement="left"
                    >
                      <MDBPopoverHeader
                        className="shiftright40"
                        style={{ minWidth: "460px" }}
                      >
                        <div className="center_div_distributed center_sides allpad usemaxSpace">
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["home_name"]}
                              </div>
                            </div>
                          </div>
                          <div> vs </div>
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["away_name"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MDBPopoverHeader>
                      <MDBPopoverBody
                        className="backgroundwhite shiftright40"
                        style={{ minWidth: "380px" }}
                      >
                        <div className="usemaxSpace">
                          <CollectOptions
                            handleTicketAdd={handleTicketAdd}
                            data={Indata}
                            index={index}
                            isStarted={isStarted}
                            isTicketError={isTicketError}
                            setisPopOpen={setisPopOpen}
                            setIsStarted={setIsStarted}
                            setIsTicketError={setIsTicketError}
                          />
                        </div>
                      </MDBPopoverBody>
                    </MDBPopover>
                  </div>
                </td>
                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            )
          ) : opt4 === "Away Team" ? (
            small ? (
              <>
                <td className="link" onClick={() => handleDropclick(index)}>
                  <IoIosArrowDropdownCircle size={20} color="gray" />
                </td>
                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            ) : (
              <>
                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov2.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov2.5"]?.score + " %"}
                  </div>
                </td>

                <td></td>

                <td className="preds">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background: Indata?.optns?.["ov3.5"]?.color,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {Indata?.optns?.["ov3.5"]?.score + " %"}
                  </div>
                </td>
                <td className="predsTable">
                  <div className="link usemaxSpace flex ">
                    <MDBPopover
                      color="grey"
                      className="center_div center_straight usemaxSpace popover"
                      btnChildren={
                        <div
                          style={{
                            border: "none",
                            maxWidth: "12px",
                            maxHeight: "12px",
                          }}
                        >
                          <IoMdAddCircle
                            className="center_div"
                            style={{ marginLeft: "-5px", marginTop: "-7px" }}
                            size={20}
                            color="orange"
                          />
                        </div>
                      }
                      placement="left"
                    >
                      <MDBPopoverHeader
                        className="shiftright40"
                        style={{ minWidth: "460px" }}
                      >
                        <div className="center_div_distributed center_sides allpad usemaxSpace">
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["home_name"]}
                              </div>
                            </div>
                          </div>
                          <div> vs </div>
                          <div>
                            <div
                              className=""
                              style={{
                                maxWidth: "120px",
                              }}
                            >
                              <div className="hidetname">
                                {Indata?.["away_name"]}
                              </div>
                            </div>
                          </div>
                        </div>
                      </MDBPopoverHeader>
                      <MDBPopoverBody
                        className="backgroundwhite shiftright40"
                        style={{ minWidth: "380px" }}
                      >
                        <div className="usemaxSpace">
                          <CollectOptions
                            handleTicketAdd={handleTicketAdd}
                            data={Indata}
                            index={index}
                            isStarted={isStarted}
                            isTicketError={isTicketError}
                            setisPopOpen={setisPopOpen}
                            setIsStarted={setIsStarted}
                            setIsTicketError={setIsTicketError}
                          />
                        </div>
                      </MDBPopoverBody>
                    </MDBPopover>
                  </div>
                </td>

                <td>
                  {Indata?.optns?.result === "pass" ? (
                    <FaCheck size={25} color="green" />
                  ) : Indata?.optns?.result === "fail" ? (
                    <RxCross2 size={25} color="red" />
                  ) : Indata?.optns?.result === "sus" ? (
                    <TiCancel size={25} color="orange" />
                  ) : (
                    <MdOutlinePending size={25} color="grey" />
                  )}
                </td>
              </>
            )
          ) : null}
        </>
      );
    };

    const handleTicketAdd = (match, index) => {
      ////console.log(match);
      const currTime = Math.floor(new Date().getTime() / 1000);
      if (match?.date_unix < currTime) {
        setIsStarted(() => true);
        setisClickIndex(() => index);
      } else {
        setIsStarted(() => false);
        const prevglobal = { ...globalUser };
        let statuses = prevglobal?.user?.Tickets?.state;
        let activeTicket = "";
        if (statuses && typeof statuses === "object") {
          for (
            let index = 0;
            index < Object?.keys(statuses && statuses)?.length;
            index++
          ) {
            const element = Object?.keys(statuses && statuses)?.[index];
            if (statuses?.[element]) {
              activeTicket = element;
            }
          }
        }
        const matches_ =
          globalUser?.["user"]?.["Tickets"]?.[activeTicket]?.["matches"];
        let exist = false;
        for (let index = 0; index < matches_?.length; index++) {
          const element = matches_?.[index];
          ////console.log(element?.match?.id, match?.id);
          if (element?.match?.id === match?.id) {
            exist = true;
            break;
          }
        }
        if (exist === false) {
          if (activeTicket === "") {
            setticketactive(() => false);
          } else {
            setticketactive(() => true);
            let new_match = { ...match };

            let homeTeam = match && match["home_data"];
            let awayTeam = match && match["away_data"];

            if (!homeTeam || !awayTeam) {
              homeTeam = {
                cleanName: match?.["home_name"],
                id: match?.["homeID"],
                image: Team_image?.[String(match?.["homeID"])]?.image,
              };
              awayTeam = {
                cleanName: match?.["away_name"],
                id: match?.["awayID"],
                image: Team_image?.[String(match?.["awayID"])]?.image,
              };
              new_match = {
                home_data: homeTeam,
                away_data: awayTeam,
                match_id: match?.id,
                match: match,
                options: {
                  half: "",
                  type: "",
                  btype: "",
                  optns: "",
                  custOpt: "",
                  optns_list: [],
                },
              };
            }
            prevglobal["user"]?.["Tickets"]?.[activeTicket]?.["matches"]?.push(
              new_match
            );
            ////console.log(new_match);
            updateGlobalUser(() => prevglobal);
            setShows(() => true);
            let ckUser = UpdateLocal({
              action: "save",
              data: prevglobal,
            });
          }
        } else {
          setBasicWarning(() => true);
        }
      }
    };
    const DisplayDrop = ({ Indata_, index }) => {
      ////console.log(Lines_);
      //const Lines = removeDuplicatesByKey(Lines_, "date_unix");
      let ownID = "";
      let oppID = "";

      ownID =
        Indata_?.statistics?.[String(Object.keys(Indata_?.header)[0])]?.homeID;
      oppID =
        Indata_?.statistics?.[String(Object.keys(Indata_?.header)[0])]?.awayID;

      const Indata = Indata_;
      const match_name = `${Math.min(ownID, oppID)}_${Math.max(ownID, oppID)}`;

      let t_d = {};
      ////console.log(Tab2);
      if (
        Tab2 === "Win-Draw-Lose" ||
        Tab2 === "WDL-Corners" ||
        Tab2 === "WDL-Cards"
      ) {
        t_d = ["Win%", "Draw%", "Loss%", "Result"];
      } else if (Tab2 === "Goals") {
        t_d = ["Overer 2.5%", "Under 2.5%", "To Score%", "No Score%"];
      } else if (Tab2 === "Cards") {
        t_d = ["Over 4.5%", "Under 4.5%"];
      } else if (Tab2 === "Corners") {
        t_d = ["Over 8.5%", "Under 8.5%"];
      }

      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      let gh21 = "";

      let ch21 = "";
      let coh22 = "";

      let ch22 = "";
      let coh23 = "";

      let ch23 = "";

      let coh21 = "";
      let halfs = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
        gh21 = "3.5";

        ch21 = "3.5";

        coh21 = "8.5";
        halfs = "ft";
        if (opt4 !== "Match") {
          ch22 = "1.5";
          gh2 = "1.5";
          gh21 = "2.5";

          coh22 = "4.5";
          ch23 = "2.5";

          coh23 = "5.5";
        }
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "1.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "0.5";

        coh21 = "4.5";
        halfs = "ht";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "1.5";

        coh21 = "4.5";
        halfs = "2h";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      }
      let gtype_ = "";

      let gt2 = "";
      if (
        Tab2 === "Win-Draw-Lose" ||
        Tab2 === "WDL-Corners" ||
        Tab2 === "WDL-Cards"
      ) {
        if (opt3 === "Over Goals " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Goals " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }
      if (Tab2 === "Cards") {
        if (opt3 === "Over Cards " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Cards " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }
      if (Tab2 === "Corners") {
        if (opt3 === "Over Corners " + half) {
          gtype_ = "over";
          gt2 = "Ov";
        } else if (opt3 === "Under Corners " + half) {
          gtype_ = "under";
          gt2 = "Un";
        }
      }
      ////console.log(halfs);
      const showOptions = (match_id) => {
        navigation("/match/" + match_id, {
          state: { match_id: match_id, tab: "MARKETS" },
        });
      };
      return (
        <div>
          <div className="center_div_distributed margin-30down">
            <div>
              <div>Time</div>
              <div>
                {convertUnixToTime(Indata && Indata["date_unix"], "shortest")}
              </div>
            </div>
            <div>
              <div>League</div>
              <div>
                {Indata?.league_detail && (
                  <ReactCountryFlag
                    countryCode={Indata?.league_detail?.country_iso}
                    svg
                    style={{
                      fontSize: "2em",
                      lineHeight: "2em",
                    }}
                    aria-label={Indata?.league_detail?.country}
                  />
                )}{" "}
                {Indata?.league_detail?.league_name}
              </div>
            </div>

            {Tab2 === "Win-Draw-Lose" ||
            Tab2 === "WDL-Corners" ||
            Tab2 === "WDL-Cards" ? (
              opt3 === "Home Win" || opt3 === "Away Win" || opt3 === "Draw" ? (
                <>
                  <div>
                    <div>{`H_Win_${halfs}%`}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.H?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.H?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{`Draw_${halfs}%`}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.D?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.D?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{`A_Win_${halfs}%`}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.A?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.A?.score + " %"}
                    </div>
                  </div>
                
                    <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
             
                </>
              ) : opt3 === "Any Team Win" ? (
                <>
                  <div>
                    <div>Any Team%</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.Any?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.Any?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "HWin EH" ? (
                <>
                  <div>
                    <div>Either Half%</div>
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.H_weh?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.H_weh?.score + " %"}
                      </div>
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "HWin EH No" ? (
                <>
                  <div>
                    <div>Home Either Half No%</div>
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.H_weh_no?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.H_weh_no?.score + " %"}
                      </div>
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "AWin EH" ? (
                <>
                  <div>
                    <div>Either Half%</div>
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.A_weh?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.A_weh?.score + " %"}
                      </div>
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "AWin EH No" ? (
                <>
                  <div>
                    <div>Away Either Half No%</div>
                    <div className="center_div">
                      <div
                        style={{
                          display: "flex",
                          width: "35px",
                          height: "20px",
                          background: Indata?.optns?.A_weh_no?.color,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {"" + Indata?.optns?.A_weh_no?.score + " %"}
                      </div>
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "D EH" ? (
                <>
                  <div>
                    <div>Draw EH%</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.deh?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.deh?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt3 === "D EH No" ? (
                <>
                  <div>
                    <div>Draw EH No%</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.deh_no?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.deh_no?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : null
            ) : Tab2 === "Goals" ? (
              opt4 === "Match" ? (
                <>
                  <div>
                    <div>{gt2 + gh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>BTS_Y%</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.bts?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>BTS_N%</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts_no"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.bts_no?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "BTS Yes" ? (
                <>
                  <div>
                    <div>{"BTS_Y%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["bts"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Home Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Away Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "BTS No" ? (
                <>
                  <div>
                    <div>{"BTS_N%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["bts_no"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["bts_no"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Home Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Away Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Home Team Goals" ? (
                <>
                  <div>
                    <div>{gt2 + gh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Home Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Away Team Goals" ? (
                <>
                  <div>
                    <div>{gt2 + gh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{"Away Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Home Team To Score" ? (
                <>
                  <div>
                    <div>{"Home Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["hs"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["hs"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Away Team To Score" ? (
                <>
                  <div>
                    <div>{"Away Score%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["as"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["as"]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh2]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh2]?.score + " %"}
                    </div>
                  </div>
                  <div>
                    <div>{gt2 + gh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov" + gh21]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov" + gh21]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : null
            ) : Tab2 === "Cards" ? (
              opt4 === "Match" ? (
                <>
                  <div>
                    <div>{ch21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{ch2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Home Team" ? (
                <>
                  <div>
                    <div>{ch22 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{ch23 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Away Team" ? (
                <>
                  <div>
                    <div>{ch22 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"" + Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{ch23 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : (
                null()
              )
            ) : Tab2 === "Corners" ? (
              opt4 === "Match" ? (
                <>
                  <div>
                    <div>{coh21 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{coh2 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Home Team" ? (
                <>
                  <div>
                    <div>{coh22 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{coh23 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>
                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : opt4 === "Away Team" ? (
                <>
                  <div>
                    <div>{coh22 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov2.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov2.5"]?.score + " %"}
                    </div>
                  </div>

                  <div>
                    <div>{coh23 + "%"}</div>
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background: Indata?.optns?.["ov3.5"]?.color,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {Indata?.optns?.["ov3.5"]?.score + " %"}
                    </div>
                  </div>

                  <div
                    className="center_div center_straight"
                    onClick={() => showOptions(Indata?.id)}
                  >
                    <div className="center_div">
                      <MdOutlineReceipt size={20} color="green" />
                    </div>

                    <IoMdAddCircle size={20} color="orange" />
                  </div>
                </>
              ) : (
                null()
              )
            ) : null}
          </div>
        </div>
      );
    };
    const MLines = ({ Lines }) => {
      const [isdropdownClick, setisdropdownClick] = useState(false);
      useEffect(() => {
        if (isStarted) {
          const timer = setTimeout(() => {
            setIsStarted(false);
          }, 3000); // Change this value to the amount of time you want the message to display for in milliseconds.

          return () => clearTimeout(timer);
        }
      }, [isStarted]);
      const handleDropclick = (index) => {
        // if the row was already open, close it
        if (isdropdownClick === index) {
          setisdropdownClick(null);
        } else {
          // otherwise, open the clicked row
          setisdropdownClick(index);
        }
      };
      ////console.log(Lines_);
      //const Lines = removeDuplicatesByKey(Lines_, "date_unix");
      let t_d = {};
      ////console.log(Tab2);
      ////console.log(Top, Tab2, half, opt3, opt4);
      let where = "";
      switch (Top) {
        case "At Home":
          where = "home";
          break;
        case "At Away":
          where = "away";
          break;
        default:
          where = "All";
      }

      let op4 = "";
      switch (opt3) {
        case "Home Win":
          op4 = "Home Win";
          break;
        case "Away Win":
          op4 = "Away Win";
          break;
        case "Draw":
          op4 = "Draw";
          break;
        case "AWin EH No":
          op4 = "Away Win EH No";
          break;
        case "Any Team Win":
          op4 = "Any Team Win";
          break;
        case "AWin EH":
          op4 = "Away Win EH";
          break;
        case "HWin EH":
          op4 = "Home Win EH";
          break;
        case "HWin EH No":
          op4 = "Home Win EH No";
          break;
        case "D EH No":
          op4 = "Draw EH No";
          break;
        case "D EH":
          op4 = "Draw EH";
          break;
      }

      if (
        Tab2 === "Win-Draw-Lose" ||
        Tab2 === "WDL-Corners" ||
        Tab2 === "WDL-Cards"
      ) {
        t_d = ["Win%", "Draw%", "Loss%", "Result"];
      } else if (Tab2 === "Goals") {
        t_d = ["Overer 2.5%", "Under 2.5%", "To Score%", "No Score%"];
      } else if (Tab2 === "Cards") {
        t_d = ["Over 4.5%", "Under 4.5%"];
      } else if (Tab2 === "Corners") {
        t_d = ["Over 8.5%", "Under 8.5%"];
      }

      let gh2 = "";

      let ch2 = "";

      let coh2 = "";
      let gh21 = "";

      let ch21 = "";
      let coh22 = "";

      let ch22 = "";
      let coh23 = "";

      let ch23 = "";

      let coh21 = "";
      let halfs = "";
      if (half === "Full Time") {
        gh2 = "2.5";

        ch2 = "5.5";

        coh2 = "9.5";
        gh21 = "3.5";

        ch21 = "3.5";

        coh21 = "8.5";
        halfs = "ft";
        if (opt4 !== "Match") {
          ch22 = "1.5";
          gh2 = "1.5";
          gh21 = "2.5";

          coh22 = "4.5";
          ch23 = "2.5";

          coh23 = "5.5";
        }
      } else if (half === "First Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "1.5";

        coh21 = "4.5";
        halfs = "ht";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      } else if (half === "Second Half") {
        gh2 = "1.5";

        ch2 = "2.5";

        coh2 = "5.5";
        gh21 = "2.5";

        ch21 = "1.5";

        coh21 = "4.5";
        halfs = "2h";
        if (opt4 !== "Match") {
          ch22 = "0.5";
          gh2 = "0.5";
          gh21 = "1.5";
          coh22 = "2.5";
          ch23 = "1.5";

          coh23 = "3.5";
        }
      }
      let gtype_ = "";
      let sorted_data = "";
      let gt2 = "";
      //console.log(Data);
      if (
        Tab2 === "Win-Draw-Lose" ||
        Tab2 === "WDL-Corners" ||
        Tab2 === "WDL-Cards"
      ) {
        sorted_data = Data?.[Tab2]?.[half]?.[op4];
      } else {
        sorted_data = Data?.[Tab2]?.[half]?.[opt3]?.[opt4];
      }

      ////console.log(halfs, Data?.[Tab2]?.[half]?.[op4]);
      return (
        <div>
          <Table
            className="outer-border-light"
            striped
            hover
            size={isGosClose ? "sm" : null}
            variant="light"
          >
            <thead>
              <tr>
                <th>Date</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th>League</th>
                  </>
                )}
                <th className="homeOR">Home</th>
                <th className="formOR">Form</th>
                <th className="statsOR">Stats</th>{" "}
                <th className="formOR">Form</th>
                <th className="awayOR">Away</th>
                {small ? null : (
                  <>
                    <th></th>
                    <th></th>
                  </>
                )}
                {Tab2 === "Win-Draw-Lose" ||
                Tab2 === "WDL-Corners" ||
                Tab2 === "WDL-Cards" ? (
                  opt3 === "Home Win" ||
                  opt3 === "Away Win" ||
                  opt3 === "Draw" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">{`H_Win_${halfs}%`}</th>{" "}
                        <th className="preds">{`Draw_${halfs}%`}</th>
                        <th className="preds">{`A_Win_${halfs}%`}</th>
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : opt3 === "Any Team Win" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">Any_Team%</th>{" "}
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : opt3 === "HWin EH" || opt3 === "AWin EH" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">Win_Either_Half%</th>{" "}
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : opt3 === "HWin EH No" || opt3 === "AWin EH No" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">Win_Either_Half_No%</th>{" "}
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : opt3 === "D EH" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">Draw_EH%</th>{" "}
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : opt3 === "D EH No" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">Draw_EH_No%</th>{" "}
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : null
                ) : Tab2 === "Goals" ? (
                  <>
                    {opt4 === "Match" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{gt2 + gh2 + "%"}</th>
                          <th className="preds">{gt2 + gh21 + "%"}</th>
                          <th></th>
                          <th className="preds">BTS_Y%</th>
                          <th className="preds">BTS_N%</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "BTS Yes" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{"BTS_Y%"}</th>
                          <th></th>
                          <th className="preds">{"Home Score%"}</th>
                          <th className="preds"></th>
                          <th className="preds">{"Away Score%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "BTS No" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{"BTS_N%"}</th>
                          <th></th>
                          <th className="preds">{"Home Score%"}</th>
                          <th className="preds"></th>
                          <th className="preds">{"Away Score%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "Home Team Goals" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{gt2 + gh2 + "%"}</th>
                          <th className="preds">{gt2 + gh21 + "%"}</th>
                          <th className="preds"></th>
                          <th className="preds"></th>
                          <th className="preds">{"Home Score%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "Away Team Goals" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{gt2 + gh2 + "%"}</th>
                          <th className="preds">{gt2 + gh21 + "%"}</th>
                          <th className="preds"></th>
                          <th className="preds"></th>
                          <th className="preds">{"Away Score%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "Home Team To Score" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{"Home Score%"}</th>
                          <th className="preds"></th>
                          <th className="preds"></th>
                          <th className="preds">{gt2 + gh2 + "%"}</th>
                          <th className="preds">{gt2 + gh21 + "%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : opt4 === "Away Team To Score" ? (
                      small ? (
                        <>
                          <th>Optns</th>
                          <th className="preds">Result</th>
                        </>
                      ) : (
                        <>
                          <th className="preds">{"Away Score%"}</th>
                          <th className="preds"></th>
                          <th className="preds"></th>
                          <th className="preds">{gt2 + gh2 + "%"}</th>
                          <th className="preds">{gt2 + gh21 + "%"}</th>
                          <th className="predsTable">
                            <MdOutlineReceipt size={20} color="green" />
                          </th>
                          <th className="preds">Result</th>
                        </>
                      )
                    ) : null}
                  </>
                ) : Tab2 === "Cards" ? (
                  opt4 !== "Match" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">{ch22 + "_%"}</th>

                        <th></th>
                        <th className="preds">{ch23 + "_%"}</th>
                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : small ? (
                    <>
                      <th>Optns</th>
                      <th className="preds">Result</th>
                    </>
                  ) : (
                    <>
                      <th className="preds">{ch21 + "_%"}</th>

                      <th></th>
                      <th className="preds">{ch2 + "_%"}</th>

                      <th className="predsTable">
                        <MdOutlineReceipt size={20} color="green" />
                      </th>
                      <th className="preds">Result</th>
                    </>
                  )
                ) : Tab2 === "Corners" ? (
                  opt4 !== "Match" ? (
                    small ? (
                      <>
                        <th>Optns</th>
                        <th className="preds">Result</th>
                      </>
                    ) : (
                      <>
                        <th className="preds">{coh22 + "_%"}</th>

                        <th></th>
                        <th className="preds">{coh23 + "_%"}</th>

                        <th className="predsTable">
                          <MdOutlineReceipt size={20} color="green" />
                        </th>
                        <th className="preds">Result</th>
                      </>
                    )
                  ) : small ? (
                    <>
                      <th>Optns</th>
                      <th className="preds">Result</th>
                    </>
                  ) : (
                    <>
                      <th className="preds">{coh21 + "_%"}</th>

                      <th></th>
                      <th className="preds">{coh2 + "_%"}</th>

                      <th className="predsTable">
                        <MdOutlineReceipt size={20} color="green" />
                      </th>
                      <th className="preds">Result</th>
                    </>
                  )
                ) : null}
              </tr>
            </thead>
            <tbody>
              {sorted_data &&
                sorted_data.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <DisplayData
                        Indata_={item}
                        key={index}
                        index={index}
                        handleDropclick={handleDropclick}
                        set_drop={handleDropclick}
                      />
                    </tr>
                    {isdropdownClick === index ? (
                      <tr colSpan="100%">
                        <td colSpan="100%">
                          <DisplayDrop Indata_={item} index={index} />
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
        </div>
      );
    };
    const Ob_Data_ = Object.keys(Data)[0];
    ////console.log(Data_);
    const DisplayWarning = () => {
      useEffect(() => {
        if (basicWarning) {
          const timer = setTimeout(() => {
            setBasicWarning(false);
          }, 2000); // Change this value to the amount of time you want the message to display for in milliseconds.

          return () => clearTimeout(timer);
        }
      }, [basicWarning]);

      return (
        <div
          className="right_content flex"
          style={{ position: "sticky", top: 100, zIndex: 2000 }}
        >
          {basicWarning && (
            <div
              className="right_content flex"
              style={{ width: small ? "100%" : "60%" }}
            >
              <div className="right_content flex">
                <Alert
                  variant="info"
                  position="top-right"
                  width="min-content"
                  open={basicWarning}
                >
                  Match already exists
                </Alert>
              </div>
            </div>
          )}
        </div>
      );
    };
    return (
      <>
        {isStarted && (
          <CallError
            header={"Match Started!!"}
            data1={
              "It looks like the match you are tying to add has already started"
            }
            funcchange={setIsStarted}
            funcuser={isStarted}
          />
        )}
        {basicWarning && (
          <CallError
            header={"Already Added!!"}
            data1={
              "It looks like the match you are tying to add is already on your ticket"
            }
            funcchange={setBasicWarning}
            funcuser={basicWarning}
          />
        )}
        {isTicketError && (
          <CallError
            header={"Match Add Error!!"}
            data1={"There are no active tickets"}
            data2={
              "Add new tickets on your right from the leagues navigation and click the toggle button to activate"
            }
            data3={"If a ticket is active, it would be visible at the top"}
            funcchange={setIsTicketError}
            funcuser={isTicketError}
          />
        )}
        <div
          className="flex_column center_div"
          style={{
            width: "",
          }}
        >
          <Modal.Body>
            <MLines Lines_={Data} />
          </Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: small ? "90%" : "60%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="warningDisplay flex_column allpad"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="margin-10down midfont bold margin30down">
                  Important Information!!
                </div>
                <div className="margin-10down" style={{ textAlign: "center" }}>
                  It is important that you do more research on each match before
                  making your pick decisions. The probability percentatge on
                  here is a combination of each team's individual performance in
                  current season and the performace between both teams in
                  previous matches overall.
                </div>
                <div className="margin-10down" style={{ textAlign: "center" }}>
                  The probability might be biased in some cases, and might not
                  reflect current team's strengths and abilities.
                </div>
                <div className="margin-30down" style={{ textAlign: "center" }}>
                  You can drill down to do more indepth analysis on the match
                  and both teams. This would give you more understanding of each
                  team's performance and strengths.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const HandleSeeMore = (val) => {
    //let currValue = monthCount;

    if (val === "seemore" && monthCount > 9) {
      setMonthCount(monthCount + 5);
    }
    if (val === "seeless" && monthCount > 14) {
      setMonthCount(monthCount - 5);
    }
  };
  function SeeMore() {
    //This handles the bradcrumbs that shows the links countr > league> teams
    return (
      <div>
        <Container className="">
          <Nav style={{ display: "flex", justifyContent: "space-between" }}>
            <NavLink onClick={() => HandleSeeMore("seeless")}>
              {"< See Less"}
            </NavLink>
            <NavLink onClick={() => HandleSeeMore("seemore")}>
              {"See More >"}
            </NavLink>
          </Nav>
        </Container>
      </div>
    );
  }
  return (
    <div className="usemaxSpace">
      <MultiLeague monthCount={monthCount} />
    </div>
  );
}
