import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Table, Alert } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IndexHeader from "../IndexHeaders";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import Spinner from "../Spinner";
import ReactCountryFlag from "react-country-flag";
import countryCodeLookup from "country-code-lookup";
import "bootstrap/dist/css/bootstrap.min.css";
import LeagueNav from "../LeagueSideBar";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { Errorlog } from "../Utils";
import { CiLock } from "react-icons/ci";
import "../../css/ThemeCss.css";

import ShowTicket from "../ShowTicketGames";
import { IoMdAddCircle } from "react-icons/io";
import { MdOutlineReceipt } from "react-icons/md";
import { TbCaretUpDownFilled } from "react-icons/tb";
import { CallError } from "../Utils";
import {
  MDBBtn,
  MDBAlert,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
} from "mdb-react-ui-kit";
import { stat } from "fs";
import IndexSubheads from "../IndexSubheader";
import { Margin } from "@mui/icons-material";
import CollectOptions from "../CollectMatchOptions";
import { CatchedStoraged } from "../manageStorage/CatchedData";
const API_URL = process.env.REACT_APP_API_URL;

function GetFixtures({
  fixData,
  setLoader,
  loading,
  isLogedin,
  fixDateCatch,
  setDatePost,
  isclickTicket,
}) {
  const navigation = useNavigate();
  const [useFixture, setFixture] = useState({});
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isGosClose, setisGosClose] = useState(false);
  const [isoptions, setIsOptions] = useState("Cards/Corners");
  const [Shows, setShows] = useState(false);
  const [haveTicket, sethaveTicket] = useState(false);
  const [ticketactive, setticketactive] = useState(false);
  const [row_league, setrow_league] = useState();
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [is1X2, setIs1X2] = useState(true);
  const [rowData, setRowData] = useState();
  const [isPopOpen, setisPopOpen] = useState(true);
  const [isStarted, setIsStarted] = useState();
  const [isTicketError, setIsTicketError] = useState(false);
  const [basicWarning, setBasicWarning] = useState(false);
  const [ismatchdownClik, setismatchdownClick] = useState(false);
  const [isClickIndex, setisClickIndex] = useState();
  //**** removing supscription */
  const issubscribed = true;
  //** */
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
    if (window.innerWidth > 1000) {
      setIsDropdownVisible(false);
    }
    if (window.innerWidth < 1360) {
      setisGosClose(true);
    } else {
      setisGosClose(false);
    }
  };
  //console.log(fixData);
  /* initMDB({ Popconfirm });
  const element = document.querySelector('.popconfirm-toggle');
  const instance = new Popconfirm(element); */
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    const fetchData = async () => {
      if (!fixData) {
        return null;
      }
      setLoader(true);
      //console.log(fixData);
      let res = {};
      let options = {
        body: JSON.stringify(fixData),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "max-age=" + 20 * 60,
        },

        seconds: 20 * 60,
      };
      let url = "/fixtures_day";
      let indexdb = "DailyFixtures"
      let SaveKey = "" + fixData?.day;

      try {
        res = await CatchedStoraged(url, options, SaveKey,indexdb);
        /* res = await axios
          .post(`${API_URL}/fixtures_day`, JSON.stringify(fixData), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {}); */
        //setLoader(false);
        //console.log(res);
        const fix_data = res;
        //console.log("loaded fetchdata", fixData, fix_data);

        if (res?.status === "success") {
          //console.log(res);
          const con_data = {
            data: fix_data?.data,
          };
          setFixture(con_data);
          setismatchdownClick(false);
          setLoader(false);
          //console.log(JSON.parse(fix_data.data));
        }
        if (globalUser && globalUser?.user?.Tickets) {
          if (
            globalUser &&
            Object?.keys(globalUser?.user?.Tickets)?.length > 3
          ) {
            sethaveTicket(() => true);
          } else {
            sethaveTicket(() => false);
          }
        }
      } catch (error) {
        //alert(error.message);
        //Errorlog("error in daily fixtures", error, "fetch search data");
      }
    };

    fetchData();
    /* return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("resize", checkScreenSize);
    }; */
  }, [fixData]);

  if (!useFixture) {
    return null;
  }
  //console.log(useFixture);
  const HandleOptions = () => {
    if (is1X2) {
      setIs1X2(false);
      setIsOptions("1X2");
    } else if (!is1X2) {
      setIs1X2(true);
      setIsOptions("Cards/Corners");
    }
  };
  function convertUnixToTime(unixTime) {
    // Create a new Date object using the unixTime (in milliseconds)
    const date = new Date(unixTime * 1000);

    // Get the local time components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format the time as HH:MM:SS
    const formattedTime = `${padZero(hours)}:${padZero(minutes)}`;

    return formattedTime;
  }

  // Helper function to pad single-digit numbers with leading zeros
  function padZero(num) {
    return String(num).padStart(2, "0");
  }
  const handleleagueclick2 = (league_id, league_name, country_name) => {
    const trns_data = {
      l_id: league_id,
      l_n: league_name,
      ctry: country_name,
    };
    //console.log(trns_data);
    navigation("/league/" + league_name, { state: trns_data });
    /* window.open("/league/" + league_name + "?ctry=" + country_name + "&l_id=" + league_id + "&l_n=" + league_name); */
  };
  //console.log(isclickTicket);
  const DisplayData = ({ data, index, handleDropclick }) => {
    const homeTeam = data && data["home_data"];
    const awayTeam = data && data["away_data"];
    //console.log(data);
    const homeGoals = JSON.parse(
      data && data["match"] && data["match"]["homeGoals"]
    );
    const awayGoals = JSON.parse(
      data && data["match"] && data["match"]["awayGoals"]
    );

    //console.log(homeGoals);
    let isNewtral = "false";
    if (
      data["match"]?.["h_win"] === "0%" &&
      data["match"]?.["draw"] === "0%" &&
      data["match"]?.["a_win"] === "0%"
    ) {
      isNewtral = "true";
    }
    data["match"]["h_win"];
    const homeGoalsLength = homeGoals.length;
    const awayGoalsLength = awayGoals.length;
    const matchStatus = data && data["match"] && data["match"]["status"];
    const match_league =
      data && data["match"] && data["match"]["competition_id"];
    //console.log(homeTeam);
    const homeWDL = data && data["match"] && data["match"]["teama_form_num"];
    const awayWDL = data && data["match"] && data["match"]["teamb_form_num"];
    const season_ = data && data["match"] && data["match"]["season"];
    const handleteamclick = ({ val, team_, league }) => {
      const team_info = {
        team: val,
        league: league,
        data: useFixture?.league_nav,
      };
      /* window.open("/team/" + team_ + "?team=" + val + "&league=" + league + "&data=" + useFixture?.league_nav); */
      navigation("/team/" + team_, { state: team_info });
    };

    const handlematchlick = (match_id, team1, team2, seas) => {
      // ///console.log(team1, team2, match_id);
      /* window.open("/match/" + match_id+ "?match_id=" + match_id + "&team1=" + team1 + "&team2=" + team2 + "&seas=" + seas); */
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    };
    //console.log(issubscribed,isLogedin)
    
    const currTime = Date.now() / 1000; // Time in seconds
    const twoHoursLater = data?.["match"]?.["date_unix"] + 2 * 60 * 60 
    //console.log(data?.["match"]?.["date_unix"] < currTime < twoHoursLater)
    return (
      <>
        <td>
          {data?.["match"]?.["date_unix"] < currTime && currTime < twoHoursLater &&
          data?.["match"]["status"] !== "complete" &&
          data?.["match"]["status"] !== "suspended" ? (
            <div className="timerfontgreen">Live</div>
          ) : data?.["match"]["status"] === "complete" ? (
            <div className="orangelabel button_div ">Fin</div>
          ) : data?.["match"]["status"] === "suspended" ? (
            <div className="redlabel button_div ">Susp</div>
          ) : (
            <>
              {" "}
              {convertUnixToTime(
                data && data["match"] && data["match"]["date_unix"]
              )}{" "}
            </>
          )}
        </td>
        {isSmallScreen ? null : (
          <>
            {" "}
            <td></td>
            <td></td>
          </>
        )}

        <td
          className="homeTable link"
          onClick={() =>
            handleteamclick({
              val: homeTeam["id"],
              team_: homeTeam["cleanName"],
              league: match_league,
            })
          }
        >
          <div
            className="center_straight alligh_right center_sides"
            style={{ maxWidth: isSmallScreen ? "90px" : "120px" }}
          >
            <div
              className=""
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                width: "100%",
                overflowWrap: "break-word",
              }}
            >
              {homeTeam["cleanName"]}
            </div>
            &nbsp;
            <div className="alligh_right">
              <img
                src={homeTeam["image"]}
                width="20"
                height="15"
                alt={homeTeam["cleanName"]}
              />
            </div>
          </div>
        </td>
        <td className="formTable">
          <div className="center_div">
            <div
              style={{
                display: "flex",
                padding: "0px",
                width: "20px",
                height: "20px",
                background: data?.match?.["teama_form_colo"],

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {homeWDL}
            </div>
          </div>
        </td>

        {matchStatus === "complete" ? (
          <td
            className="formTable link"
            onClick={() =>
              handlematchlick(
                data?.match_id,
                homeTeam["id"],
                awayTeam["id"],
                season_
              )
            }
          >
            {homeGoalsLength} : {awayGoalsLength}
          </td>
        ) : (
          <td
            className="formTable link"
            onClick={() =>
              handlematchlick(
                data?.match_id,
                homeTeam["id"],
                awayTeam["id"],
                season_
              )
            }
          >
            {" "}
            -:-
          </td>
        )}

        <td
          className="formTable"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <div className="center_div">
            <div
              style={{
                display: "flex",
                width: "20px",
                height: "20px",
                background: data?.match?.["teamb_form_colo"],
                alignItems: "center",

                justifyContent: "center",
              }}
            >
              {awayWDL}
            </div>
          </div>
        </td>
        <td
          className="awayTable link"
          onClick={() =>
            handleteamclick({
              val: awayTeam["id"],
              team_: awayTeam["cleanName"],
              league: match_league,
            })
          }
        >
          <div
            className="center_straight center_sides"
            style={{ maxWidth: isSmallScreen ? "90px" : "120px" }}
          >
            <div>
              <img
                src={awayTeam["image"]}
                width="20"
                height="15"
                alt={awayTeam["cleanName"]}
              />
            </div>
            <div
              className=""
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                width: "100%",
                overflowWrap: "break-word",
              }}
            >
              {" "}
              &nbsp;{awayTeam["cleanName"]}
            </div>
          </div>
        </td>
        {isMidScreen ? (
          <>
            {is1X2 ? (
              <>
                <td className="predsTable">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background:
                        isNewtral === "true"
                          ? "grey"
                          : data?.match?.["h_win_colo"],
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {isNewtral === "true"
                      ? "?"
                      : data && data["match"] && data["match"]["h_win"]}
                  </div>
                </td>
                <td className="predsTable">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background:
                        isNewtral === "true"
                          ? "grey"
                          : data?.match?.["draw_colo"],
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {isNewtral === "true"
                      ? "?"
                      : data && data["match"] && data["match"]["draw"]}
                  </div>
                </td>
                <td className="predsTable">
                  <div
                    style={{
                      display: "flex",
                      width: "35px",
                      height: "20px",
                      background:
                        isNewtral === "true"
                          ? "grey"
                          : data?.match?.["a_win_colo"],
                      alignItems: "center",
                      marginLeft: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {isNewtral === "true"
                      ? "?"
                      : data && data["match"] && data["match"]["a_win"]}
                  </div>
                </td>
                {data?.["match"]?.["o25"] === 0 ? (
                  <td className="predsTable"> - </td>
                ) : (
                  <td className="predsTable">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background:
                          isNewtral === "true"
                            ? "grey"
                            : data?.match?.["o25_colo"],
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isNewtral === "true" ? "?" : data?.["match"]?.["o25"]}
                    </div>
                  </td>
                )}{" "}
                <td className="predsTable">
                  <div className="link usemaxSpace flex ">
                    {
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          style={{ minWidth: "460px" }}
                          className="shiftright100"
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {homeTeam["cleanName"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {awayTeam["cleanName"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright100"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="usemaxSpace ">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={data}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    }
                  </div>
                </td>
              </>
            ) : (
              <>
                {data?.["match"]?.["o25"] === 0 ? (
                  <td className="predsTable"> - </td>
                ) : (
                  <td className="predsTable">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background:
                          isNewtral === "true"
                            ? "grey"
                            : data?.match?.["o25_colo"],
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isNewtral === "true" ? "?" : data?.["match"]?.["o25"]}
                    </div>
                  </td>
                )}

                {data && data["match"] && data["match"]["bts"] === 0 ? (
                  <td className="predsTable"> - </td>
                ) : (
                  <td className="predsTable">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background:
                          isNewtral === "true"
                            ? "grey"
                            : !issubscribed
                            ? ""
                            : data?.match?.["bts_colo"],
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isNewtral === "true" ? (
                        "?"
                      ) : issubscribed ? (
                        data && data["match"] && data["match"]["bts"]
                      ) : (
                        <CiLock />
                      )}
                    </div>
                  </td>
                )}

                {data && data["match"] && data["match"]["card"] === 0 ? (
                  <td className="predsTable"> - </td>
                ) : (
                  <td className="predsTable">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background:
                          isNewtral === "true"
                            ? "grey"
                            : !issubscribed
                            ? ""
                            : data?.match?.["card_colo"],
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isNewtral === "true" ? (
                        "?"
                      ) : !issubscribed ? (
                        <CiLock />
                      ) : (
                        data && data["match"] && data["match"]["card"]
                      )}
                    </div>
                  </td>
                )}

                {data && data["match"] && data["match"]["corners"] === 0 ? (
                  <td className="predsTable"> - </td>
                ) : (
                  <td className="predsTable">
                    <div
                      style={{
                        display: "flex",
                        width: "35px",
                        height: "20px",
                        background:
                          isNewtral === "true"
                            ? "grey"
                            : !issubscribed
                            ? ""
                            : data?.match?.["corners_colo"],
                        alignItems: "center",
                        marginLeft: "10px",
                        justifyContent: "center",
                      }}
                    >
                      {isNewtral === "true" ? (
                        "?"
                      ) : !issubscribed ? (
                        <CiLock />
                      ) : (
                        data && data["match"] && data["match"]["corners"]
                      )}
                    </div>
                  </td>
                )}
                <td className="predsTable">
                  <div className="link usemaxSpace flex ">
                    {
                      <MDBPopover
                        color="grey"
                        className="center_div center_straight usemaxSpace popover"
                        btnChildren={
                          <div
                            style={{
                              border: "none",
                              maxWidth: "12px",
                              maxHeight: "12px",
                            }}
                          >
                            <IoMdAddCircle
                              className="center_div"
                              style={{ marginLeft: "-5px", marginTop: "-7px" }}
                              size={20}
                              color="orange"
                            />
                          </div>
                        }
                        placement="left"
                      >
                        <MDBPopoverHeader
                          style={{ minWidth: "460px" }}
                          className="shiftright100"
                        >
                          <div className="center_div_distributed center_sides allpad usemaxSpace">
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {homeTeam["cleanName"]}
                                </div>
                              </div>
                            </div>
                            <div> vs </div>
                            <div>
                              <div
                                className=""
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <div className="hidetname">
                                  {awayTeam["cleanName"]}
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBPopoverHeader>
                        <MDBPopoverBody
                          className="backgroundwhite shiftright100"
                          style={{ minWidth: "460px" }}
                        >
                          <div className="usemaxSpace ">
                            <CollectOptions
                              handleTicketAdd={handleTicketAdd}
                              data={data}
                              index={index}
                              isStarted={isStarted}
                              isTicketError={isTicketError}
                              setisPopOpen={setisPopOpen}
                              setIsStarted={setIsStarted}
                              setIsTicketError={setIsTicketError}
                            />
                          </div>
                        </MDBPopoverBody>
                      </MDBPopover>
                    }
                  </div>
                </td>
              </>
            )}
          </>
        ) : isSmallScreen ? (
          <td className="link" onClick={() => handleDropclick(index)}>
            <IoIosArrowDropdownCircle size={20} color="gray" />
          </td>
        ) : (
          <>
            <td className="predsTable">
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true" ? "grey" : data?.match?.["h_win_colo"],
                  alignItems: "center",
                  marginLeft: "10px",
                  justifyContent: "center",
                }}
              >
                {isNewtral === "true"
                  ? "?"
                  : data && data["match"] && data["match"]["h_win"]}
              </div>
            </td>
            <td className="predsTable">
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true" ? "grey" : data?.match?.["draw_colo"],
                  alignItems: "center",
                  marginLeft: "10px",
                  justifyContent: "center",
                }}
              >
                {isNewtral === "true"
                  ? "?"
                  : data && data["match"] && data["match"]["draw"]}
              </div>
            </td>
            <td className="predsTable">
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true" ? "grey" : data?.match?.["a_win_colo"],
                  alignItems: "center",
                  marginLeft: "10px",
                  justifyContent: "center",
                }}
              >
                {isNewtral === "true"
                  ? "?"
                  : data && data["match"] && data["match"]["a_win"]}
              </div>
            </td>

            {data?.["match"]?.["o25"] === 0 ? (
              <td className="predsTable"> - </td>
            ) : (
              <td className="predsTable">
                <div
                  style={{
                    display: "flex",
                    width: "35px",
                    height: "20px",
                    background:
                      isNewtral === "true" ? "grey" : data?.match?.["o25_colo"],
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "center",
                  }}
                >
                  {isNewtral === "true" ? "?" : data?.["match"]?.["o25"]}
                </div>
              </td>
            )}

            {data && data["match"] && data["match"]["bts"] === 0 ? (
              <td className="predsTable"> - </td>
            ) : (
              <td className="predsTable">
                <div
                  style={{
                    display: "flex",
                    width: "35px",
                    height: "20px",
                    background:
                      isNewtral === "true"
                        ? "grey"
                        : !issubscribed
                        ? ""
                        : data?.match?.["bts_colo"],
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "center",
                  }}
                >
                  {isNewtral === "true" ? (
                    "?"
                  ) : !issubscribed ? (
                    <CiLock />
                  ) : (
                    data && data["match"] && data["match"]["bts"]
                  )}
                </div>
              </td>
            )}

            {data && data["match"] && data["match"]["card"] === 0 ? (
              <td className="predsTable"> - </td>
            ) : (
              <td className="predsTable">
                <div
                  style={{
                    display: "flex",
                    width: "35px",
                    height: "20px",
                    background:
                      isNewtral === "true"
                        ? "grey"
                        : !issubscribed
                        ? ""
                        : data?.match?.["card_colo"],
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "center",
                  }}
                >
                  {isNewtral === "true" ? (
                    "?"
                  ) : !issubscribed ? (
                    <CiLock />
                  ) : (
                    data && data["match"] && data["match"]["card"]
                  )}
                </div>
              </td>
            )}

            {data && data["match"] && data["match"]["corners"] === 0 ? (
              <td className="predsTable"> - </td>
            ) : (
              <td className="predsTable">
                <div
                  style={{
                    display: "flex",
                    width: "35px",
                    height: "20px",
                    background:
                      isNewtral === "true"
                        ? "grey"
                        : !issubscribed
                        ? ""
                        : data?.match?.["corners_colo"],
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "center",
                  }}
                >
                  {isNewtral === "true" ? (
                    "?"
                  ) : !issubscribed ? (
                    <CiLock />
                  ) : (
                    data && data["match"] && data["match"]["corners"]
                  )}
                </div>
              </td>
            )}
            <td className="predsTable">
              <div className="link usemaxSpace flex ">
                {
                  <MDBPopover
                    color="grey"
                    className="center_div center_straight usemaxSpace popover"
                    btnChildren={
                      <div
                        style={{
                          border: "none",
                          maxWidth: "12px",
                          maxHeight: "12px",
                        }}
                      >
                        <IoMdAddCircle
                          className="center_div"
                          style={{ marginLeft: "-5px", marginTop: "-7px" }}
                          size={20}
                          color="orange"
                        />
                      </div>
                    }
                    placement="left"
                  >
                    <MDBPopoverHeader
                      style={{ minWidth: "460px" }}
                      className="shiftright100"
                    >
                      <div className="center_div_distributed center_sides allpad usemaxSpace">
                        <div>
                          <div
                            className=""
                            style={{
                              maxWidth: "120px",
                            }}
                          >
                            <div className="hidetname">
                              {homeTeam["cleanName"]}
                            </div>
                          </div>
                        </div>
                        <div> vs </div>
                        <div>
                          <div
                            className=""
                            style={{
                              maxWidth: "120px",
                            }}
                          >
                            <div className="hidetname">
                              {awayTeam["cleanName"]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBPopoverHeader>
                    <MDBPopoverBody
                      className="backgroundwhite shiftright100"
                      style={{ minWidth: "460px" }}
                    >
                      <div className="usemaxSpace ">
                        <CollectOptions
                          handleTicketAdd={handleTicketAdd}
                          data={data}
                          index={index}
                          isStarted={isStarted}
                          isTicketError={isTicketError}
                          setisPopOpen={setisPopOpen}
                          setIsStarted={setIsStarted}
                          setIsTicketError={setIsTicketError}
                        />
                      </div>
                    </MDBPopoverBody>
                  </MDBPopover>
                }
              </div>
            </td>
          </>
        )}
      </>
    );
  };
  const handleTicketAdd = (match, index) => {
    //console.log(match);
    const currTime = Math.floor(new Date().getTime() / 1000);
    if (match?.match?.date_unix < currTime) {
      setIsStarted(() => true);
      setisClickIndex(() => index);
    } else {
      setIsStarted(() => false);
      const prevglobal = { ...globalUser };
      let statuses = prevglobal?.user?.Tickets?.state;
      let activeTicket = "";
      if (statuses && typeof statuses === "object") {
        for (
          let index = 0;
          index < Object.keys(statuses && statuses)?.length;
          index++
        ) {
          const element = Object.keys(statuses && statuses)[index];
          if (statuses?.[element]) {
            activeTicket = element;
          }
        }
      }
      //console.log();
      const matches_ = globalUser["user"]["Tickets"][activeTicket]["matches"];
      let exist = false;
      for (let index = 0; index < matches_.length; index++) {
        const element = matches_[index];
        //console.log(element?.match?.id, match?.match?.id);
        if (element?.match?.id === match?.match?.id) {
          exist = true;
          break;
        }
      }
      if (exist === false) {
        setBasicWarning(() => false);
        if (activeTicket === "") {
          setticketactive(() => false);
        } else {
          setticketactive(() => true);
          const new_match = { ...match };
          new_match["options"] = {
            half: "",
            type: "",
            btype: "",
            optns: "",
            custOpt: "",
            optns_list: [],
          };
          prevglobal["user"]["Tickets"][activeTicket]["matches"].push(
            new_match
          );
          updateGlobalUser(() => prevglobal);
          setShows(() => true);
          let ckUser = UpdateLocal({
            action: "save",
            data: prevglobal,
          });
        }
      } else {
        setBasicWarning(() => true);
      }
    }
  };
  const DisplayDrop = ({ data, index }) => {
    //console.log(data);
    let isNewtral = "false";
    if (
      data["match"]?.["h_win"] === "0%" &&
      data["match"]?.["draw"] === "0%" &&
      data["match"]?.["a_win"] === "0%"
    ) {
      isNewtral = "true";
    }
    const showOptions = (match_id) => {
      navigation("/match/" + match_id, {
        state: { match_id: match_id, tab: "MARKETS" },
      });
    };
    return (
      <div>
        <div className="center_div_distributed margin-30down">
          <div className="center_div center_straight">
            <div>Home Win</div>
            <div
              style={{
                display: "flex",
                width: "35px",
                height: "20px",
                background:
                  isNewtral === "true" ? "grey" : data?.match?.["h_win_colo"],
                alignItems: "center",

                justifyContent: "center",
              }}
            >
              {isNewtral === "true"
                ? "?"
                : data && data?.["match"] && data?.["match"]?.["h_win"]}
            </div>
          </div>
          <div className="center_div center_straight">
            <div>Draw</div>
            <div
              style={{
                display: "flex",
                width: "35px",
                height: "20px",
                background:
                  isNewtral === "true" ? "grey" : data?.match?.["draw_colo"],
                alignItems: "center",

                justifyContent: "center",
              }}
            >
              {isNewtral === "true"
                ? "?"
                : data && data?.["match"] && data?.["match"]?.["draw"]}
            </div>
          </div>
          <div className="center_div center_straight">
            <div>Away Win</div>
            <div
              style={{
                display: "flex",
                width: "35px",
                height: "20px",
                background:
                  isNewtral === "true" ? "grey" : data?.match?.["a_win_colo"],
                alignItems: "center",

                justifyContent: "center",
              }}
            >
              {isNewtral === "true"
                ? "?"
                : data && data?.["match"] && data?.["match"]?.["a_win"]}
            </div>
          </div>
        </div>
        <div className=" ">
          <div className="center_div_distributed ">
            <div className="center_div center_straight">
              <div>Over 2.5</div>
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true" ? "grey" : data?.match?.["o25_colo"],
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                {isNewtral === "true" ? "?" : data?.["match"]?.["o25"]}
              </div>
            </div>
            <div className="center_div center_straight">
              <div> BTS</div>
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true"
                      ? ""
                      : !issubscribed
                      ? "grey"
                      : data?.match?.["bts_colo"],
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                {isNewtral === "true" ? (
                  "?"
                ) : !issubscribed ? (
                  <CiLock />
                ) : (
                  data && data?.["match"] && data?.["match"]?.["bts"]
                )}
              </div>
            </div>
            <div className="center_div center_straight">
              <div>Ov3.5 CDs</div>
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true"
                      ? ""
                      : !issubscribed
                      ? "grey"
                      : data?.match?.["card_colo"],
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                {isNewtral === "true" ? (
                  "?"
                ) : !issubscribed ? (
                  <CiLock />
                ) : (
                  data && data?.["match"] && data?.["match"]?.["card"]
                )}
              </div>
            </div>
            <div className="center_div center_straight">
              <div>Ov8.5 CNs</div>
              <div
                style={{
                  display: "flex",
                  width: "35px",
                  height: "20px",
                  background:
                    isNewtral === "true"
                      ? "grey"
                      : !issubscribed
                      ? ""
                      : data?.match?.["corners_colo"],
                  alignItems: "center",

                  justifyContent: "center",
                }}
              >
                {isNewtral === "true" ? (
                  "?"
                ) : !issubscribed ? (
                  <CiLock />
                ) : (
                  data && data?.["match"] && data?.["match"]?.["corners"]
                )}
              </div>
            </div>
            <div
              className="center_div center_straight"
              onClick={() => showOptions(data?.match?.id)}
            >
              <div className="center_div">
                <MdOutlineReceipt size={20} color="green" />
              </div>

              <IoMdAddCircle size={20} color="orange" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const MatchRows = ({ data, data2, index2, ismatchdownClik }) => {
    const [isdropdownClick, setisdropdownClick] = useState(false);
    useEffect(() => {
      if (isStarted) {
        const timer = setTimeout(() => {
          setIsStarted(false);
        }, 3000); // Change this value to the amount of time you want the message to display for in milliseconds.

        return () => clearTimeout(timer);
      }
    }, [isStarted]);

    const handleDropclick = (index) => {
      // if the row was already open, close it
      if (isdropdownClick === index) {
        setisdropdownClick(null);
      } else {
        // otherwise, open the clicked row
        setisdropdownClick(index);
      }
    };
    //console.log(data)
    return (
      <Container className="">
        <Table
          className="standard_table"
          size={isGosClose ? "sm" : null}
          striped
          hover
          variant="light"
        >
          <thead>
            <tr>
              <th>Time</th>
              {isSmallScreen ? null : (
                <>
                  <th></th>
                  <th></th>
                </>
              )}
              <th className="homeTable">Home</th>
              <th className="formTable">Form</th>
              <th className="statsTable">Stats</th>{" "}
              {/* Add a header for the 'stats' column */}
              <th className="formTable">Form</th>{" "}
              {/* Update header name to match the data */}
              <th className="awayTable">Away</th>{" "}
              {/* Add a header for the 'away' column */}
              {isMidScreen ? (
                <>
                  {is1X2 ? (
                    <>
                      <th className="predsTable">H_Win</th>{" "}
                      {/* Update header name to match the data */}
                      <th className="predsTable">Draw</th>{" "}
                      {/* Update header name to match the data */}
                      <th className="predsTable">A_Win</th>{" "}
                      {/* Update header name to match the data */}
                      <th className="preds">O_2.5G</th>
                      <th className="predsTable">
                        <MdOutlineReceipt size={20} color="green" />
                      </th>
                    </>
                  ) : (
                    <>
                      <th className="preds">O_2.5G</th>
                      <th className="predsTable">BTS</th>
                      {}
                      <th className="predsTable">O_3.5CD</th>
                      <th className="predsTable">O_8.5CN</th>
                      <th className="predsTable">
                        <MdOutlineReceipt size={20} color="green" />
                      </th>
                    </>
                  )}
                </>
              ) : isSmallScreen ? (
                <>
                  <th className="predsTable">Optns</th>
                </>
              ) : (
                <>
                  <th className="predsTable">H_Win</th>{" "}
                  {/* Update header name to match the data */}
                  <th className="predsTable">Draw</th>{" "}
                  {/* Update header name to match the data */}
                  <th className="predsTable">A_Win</th>{" "}
                  {/* Update header name to match the data */}
                  <th className="preds">O_2.5G</th>
                  <th className="predsTable">BTS</th>
                  {}
                  <th className="predsTable">O3.5CD</th>
                  <th className="predsTable">O8.5CN</th>
                  <th className="predsTable">
                    <MdOutlineReceipt size={20} color="green" />
                  </th>
                </>
              )}
              {/* Update header name to matc the data */}
            </tr>
          </thead>
          <tbody>
            {data?.["matches"]?.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <DisplayData
                    data={item}
                    key={index}
                    index={index}
                    handleDropclick={handleDropclick}
                    set_drop={handleDropclick}
                  />
                </tr>

                {isdropdownClick === index ? (
                  <tr colSpan="100%">
                    <td colSpan="100%">
                      <DisplayDrop data={item} index={index} />
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  };

  function Fixtures() {
    useEffect(() => {}, [ismatchdownClik, rowData, setRowData]);

    //console.log(rowData)

    let data_arr = Object.assign({}, useFixture?.data);

    const countries = useFixture?.data?.countries;
    const full_arr = useFixture?.data;
    delete data_arr.countries;
    const data_arr_top = Object.values(data_arr?.top);
    const data_arr_rest = Object.values(data_arr?.rest);

    if (!useFixture && useFixture.data) {
      return null;
    }
    //console.log(countries);

    const getCCode = (countries, leagueCountry) => {
      //console.log(countries);
      for (let index = 0; index < countries?.length; index++) {
        const element = countries?.[index];
        if (element["country"] === leagueCountry) {
          const value = element["iso"];
          //console.log(value, leagueCountry);
          return value;
        }
      }
    };

    /* const getCountryCode = (countryName) => {
      const countryCode = countryCodeLookup.byCountry(countryName);

      return countryCode ? countryCode.iso2 : getCCode(countries, countryName);
    }; */
    const fetchData = async (league) => {
      const data = { ...fixData };
      data["league"] = league;
      let res = "";
      try {
        res = await axios
          .post(`${API_URL}/fixtures_day_row`, JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch((error) => {});
      } catch (error) {
        alert(error.message);
        //Errorlog("error in daily fixtures", error, "fetch search data");
      }
      //console.log(res)
      if (res?.data?.status === "success") {
        //console.log("entered", res?.data?.data);
        return { status: "pass", data: res };
      } else {
        return { status: "fail" };
      }
    };
    const handlematchesClick = async (index, league) => {
      // if the row was already open, close it
      const res = await fetchData(league);
      //console.log("returned to habdle", res);
      setismatchdownClick(index);
      setrow_league(league);
      /* if (ismatchdownClik === index) {
        setismatchdownClick(false);
        setrow_league(league)
      } else {
        // otherwise, open the clicked row
        setrow_league(league)
        setismatchdownClick(index);
      } */
      if (res?.status === "pass") {
        //console.log("passed");
        setRowData(res?.data?.data?.data?.[fixData?.day]);
      }
    };
    //console.log(rowData,row_league,fixData,ismatchdownClik);

    const RenderFixtures = ({
      dataArr,
      leagueName,
      index,
      handlematchesClick,
    }) => {
      const { league_info, matches } = dataArr || {};
      //console.log(dataArr, full_arr)
      //const leagueImgUrl = league_info && league_info.image;
      const leagueCountry = league_info && league_info.country;
      const countryCode = getCCode(countries, leagueCountry);
      const league_id =
        matches?.[0]?.match?.competition_id || league_info?.comp_id;
      //console.log(dataArr, matches,league_id,leagueName, leagueCountry);

      return (
        <Container className="usemaxSpace">
          <div style={{ width: "100%" }}>
            <div
              className="tableheader center_div_distributed center_sides margin-10down"
              onClick={() =>
                ismatchdownClik &&
                setismatchdownClick((prevchangeName) => !prevchangeName)
              }
              style={{ minWidth: "80%" }}
            >
              <div
                className="center_div  center_sides shiftright_left link forceblack"
                onClick={() =>
                  handleleagueclick2(league_id, leagueName, leagueCountry)
                }
              >
                <div className="">
                  {countryCode && (
                    <ReactCountryFlag
                      countryCode={getCCode(countries, leagueCountry)}
                      svg
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label={leagueCountry}
                    />
                  )}
                </div>{" "}
                &nbsp;
                <div className=" ">
                  {leagueCountry} - {leagueName}
                </div>
              </div>

              {(ismatchdownClik === index || matches) && isMidScreen ? (
                <div className="shiftleft_right">
                  <div
                    onClick={() => HandleOptions()}
                    className="smartTableButton link forceblack"
                  >
                    {isoptions}
                  </div>
                </div>
              ) : null}

              {ismatchdownClik !== index && !matches && (
                <div
                  className="allpad center_straight center_sides link forceblack"
                  onClick={() => {
                    handlematchesClick(index, leagueName);
                  }}
                >
                  <div className="divgreorgButton">
                    &nbsp;{dataArr?.match_count}&nbsp;
                  </div>{" "}
                  &nbsp; <TbCaretUpDownFilled size={20} color="green" />{" "}
                </div>
              )}
            </div>
          </div>
          {matches && (
            <div style={{ width: "100%" }}>
              <Row>
                <MatchRows
                  data={dataArr}
                  index2={index}
                  data2={rowData}
                  ismatchdownClik={ismatchdownClik}
                />
              </Row>
            </div>
          )}
          {ismatchdownClik === index && !matches && rowData && (
            <div style={{ width: "100%" }}>
              <Row>
                <MatchRows
                  data={rowData?.[row_league]}
                  index2={index}
                  ismatchdownClik={ismatchdownClik}
                />
              </Row>
            </div>
          )}
        </Container>
      );
    };
    //console.log(data_arr)
    return (
      <div>
        <div>
          {data_arr_top
            .sort((a, b) =>
              a.league_info.country.localeCompare(b.league_info.country)
            )
            .map((item, index) => (
              <RenderFixtures
                key={index}
                dataArr={item}
                leagueName={item?.league_info?.league_name}
                index={index}
              />
            ))}
        </div>
        <div>
          {data_arr_rest
            .sort((a, b) =>
              a.league_info.country.localeCompare(b.league_info.country)
            )
            .map((item, index) => (
              <RenderFixtures
                key={index}
                dataArr={item}
                handlematchesClick={handlematchesClick}
                leagueName={item?.league_info?.league_name}
                index={index}
              />
            ))}
        </div>
      </div>
    );
  }
  const DisplayDay = () => {
    const date_raw = fixData && fixData.day;
    const date = new Date(date_raw);

    // Get the user's time zone offset in minutes
    const timeZoneOffsetMinutes = date.getTimezoneOffset();

    // Calculate the offset in hours and format it as ±HH:mm
    const timeZoneOffsetHours = Math.abs(timeZoneOffsetMinutes / 60);
    const timeZoneOffsetSign = timeZoneOffsetMinutes < 0 ? "+" : "-";
    const timeZoneOffsetFormatted = `${timeZoneOffsetSign}${String(
      timeZoneOffsetHours
    ).padStart(2, "0")}:${String(timeZoneOffsetMinutes % 60).padStart(2, "0")}`;

    // Create a new date by applying the time zone offset
    const dateInBrowserTimeZone = new Date(
      date.getTime() + timeZoneOffsetMinutes * 60 * 1000
    );

    const options = { weekday: "long" };
    const day = dateInBrowserTimeZone.toLocaleDateString("en-US", options);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <h6>
          Game day: {day} ({date_raw})
        </h6>
      </div>
    );
  };
  //console.log(useFixture?.league_nav);
  const DisplayWarning = () => {
    useEffect(() => {
      if (basicWarning) {
        const timer = setTimeout(() => {
          setBasicWarning(false);
        }, 2000); // Change this value to the amount of time you want the message to display for in milliseconds.

        return () => clearTimeout(timer);
      }
    }, [basicWarning]);

    return (
      <div
        className="right_content flex"
        style={{ position: "sticky", top: 100, zIndex: 2000 }}
      >
        {basicWarning && (
          <div
            className="right_content flex"
            style={{ width: isSmallScreen ? "100%" : "60%" }}
          >
            <div className="right_content flex">
              <Alert
                variant="info"
                position="top-right"
                width="min-content"
                open={basicWarning}
              >
                Match already exists
              </Alert>
            </div>
          </div>
        )}
      </div>
    );
  };
  //console.log(useFixture);
  return (
    <>
      <IndexSubheads
        fix_date={setDatePost}
        sidenavOpen={sidenavOpen}
        setSidenavOpen={setSidenavOpen}
        islgedin={isLogedin}
        issubed={issubscribed}
        setticketactive={setticketactive}
        setShows={setShows}
        type={"dayfix"}
      />

      {isMidScreen || isSmallScreen ? (
        <>
          <div className="usemaxSpace">
            {isStarted && (
              <CallError
                header={"Match Started!!"}
                data1={
                  "It looks like the match you are tying to add has already started"
                }
                funcchange={setIsStarted}
                funcuser={isStarted}
              />
            )}
            {isTicketError && (
              <CallError
                header={"Match Add Error!!"}
                data1={"There are no active tickets"}
                data2={
                  "Add new tickets on your right from the leagues navigation and click the toggle button to activate"
                }
                data3={"If a ticket is active, it would be visible at the top"}
                funcchange={setIsTicketError}
                funcuser={isTicketError}
              />
            )}
            {basicWarning && (
              <CallError
                header={"Already Added!!"}
                data1={
                  "It looks like the match you are tying to add is already on your ticket"
                }
                funcchange={setBasicWarning}
                funcuser={basicWarning}
              />
            )}

            {/* {isMidScreen || isSmallScreen ? (
              <>
                <Container className="left_div">
                  <Button
                    style={{ marginRight: "0px" }}
                    className="webButton margin10down forceblack"
                    onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  >
                    Top Leagues
                  </Button>
                </Container>
              </>
            ) : null} */}
            {(isDropdownVisible && isMidScreen) ||
            (isDropdownVisible && isSmallScreen) ? (
              <div className="">
                <div className="usemaxSpace center_div">
                  <div>
                    <LeagueNav
                      islgedin={isLogedin}
                      issubed={issubscribed}
                      setticketactive={setticketactive}
                      setShows={setShows}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="usemaxSpace center_div">
              {!isSmallScreen ? (
                <div className="usemaxSpace">
                  {isMidScreen || isSmallScreen ? null : (
                    <div>
                      <div>
                        <LeagueNav
                          islgedin={isLogedin}
                          issubed={issubscribed}
                          setticketactive={setticketactive}
                          setShows={setShows}
                        />
                      </div>
                    </div>
                  )}

                  <div className="usemaxSpace">
                    <div className="flex center_straight">
                      {ticketactive ? null : haveTicket ? (
                        <div style={{ color: "red" }}>Ticket not Active</div>
                      ) : null}
                      {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows} />
                        </div>
                      )} */}
                      <div className=" margin10down usemaxSpace">
                        {loading ? <Spinner /> : useFixture && <Fixtures />}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div sm="12" xs="12" md="12" className="">
                    <div className="center_div center_straight">
                      {ticketactive ? null : haveTicket ? (
                        <div style={{ color: "red" }}>Ticket not Active</div>
                      ) : null}
                      {/* {isclickTicket && (
                        <div>
                          <ShowTicket show={Shows} />
                        </div>
                      )} */}
                      <div className="margin10down ">
                        {loading ? <Spinner /> : useFixture && <Fixtures />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Container className="usemaxSpace">
          {isStarted && (
            <CallError
              header={"Match Started!!"}
              data1={
                "It looks like the match you are tying to add has already started"
              }
              funcchange={setIsStarted}
              funcuser={isStarted}
            />
          )}
          {basicWarning && (
            <CallError
              header={"Already Added!!"}
              data1={
                "It looks like the match you are tying to add is already on your ticket"
              }
              funcchange={setBasicWarning}
              funcuser={basicWarning}
            />
          )}
          {isTicketError && (
            <CallError
              header={"Match Add Error!!"}
              data1={"There are no active tickets"}
              data2={
                "Add new tickets on your right from the leagues navigation and click the toggle button to activate"
              }
              data3={"If a ticket is active, it would be visible at the top"}
              funcchange={setIsTicketError}
              funcuser={isTicketError}
            />
          )}

          {/* {isMidScreen || isSmallScreen ? (
            <>
              <Button
                className="webButton margin10down"
                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
              >
                Top Leagues
              </Button>
            </>
          ) : null} */}
          {(isDropdownVisible && isMidScreen) ||
          (isDropdownVisible && isSmallScreen) ? (
            <div className="">
              <div>
                <div style={{ width: "20%" }}>
                  <LeagueNav
                    islgedin={isLogedin}
                    issubed={issubscribed}
                    setticketactive={setticketactive}
                    setShows={setShows}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {!isSmallScreen ? (
            <div className="center_straight center_sides">
              {isMidScreen || isSmallScreen ? null : (
                <div style={{ width: "20%" }}>
                  <div>
                    <LeagueNav
                      islgedin={isLogedin}
                      issubed={issubscribed}
                      setticketactive={setticketactive}
                      setShows={setShows}
                    />
                  </div>
                </div>
              )}

              <div style={{ width: "100%" }}>
                <div className="flex center_straight">
                  {ticketactive ? null : haveTicket ? (
                    <div style={{ color: "red" }}>Ticket not Active</div>
                  ) : null}
                  {/* {isclickTicket && (
                    <div>
                      <ShowTicket show={Shows} />
                    </div>
                  )} */}
                  <div className=" margin10down" style={{ width: "100%" }}>
                    {loading ? <Spinner /> : useFixture && <Fixtures />}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="usemaxSpace left_div">
                <div className="flex center_straight">
                  {ticketactive ? null : haveTicket ? (
                    <div style={{ color: "red" }}>Ticket not Active</div>
                  ) : null}
                  {/* {isclickTicket && (
                    <div className="usemaxSpace">
                      <ShowTicket show={Shows} />
                    </div>
                  )} */}
                  <div className=" margin10down usemaxSpace">
                    {loading ? <Spinner /> : useFixture && <Fixtures />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  );
}

export default GetFixtures;
