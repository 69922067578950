import React, { useRef } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBPopconfirm,
  MDBPopconfirmMessage,
} from "mdb-react-ui-kit";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import { SlNote } from "react-icons/sl";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import axios from "axios";
import { GlobalUserContext } from "../contextRouters/UserInfoContext";
import { useState, useEffect, useContext } from "react";
import { Nav, Button, Col, Container, Form, Row } from "react-bootstrap";
import FooterAll from "../Footer";
import IndexHeader from "../IndexHeaders";
import { RxCross2 } from "react-icons/rx";
import { UpdateLocal } from "../manageStorage/MaintainLocal";
import ImageUpload from "../GetUserImage";
import DisplayComments from "../CommentComponents/DicplayComments";
import care from "../../images/unisex.jpg";
import bgimage from "../../images/uniback.jpg";
import "../../css/ThemeCss.css";
import { FiThumbsUp } from "react-icons/fi";
import { IoHeart } from "react-icons/io5";
import League_images from "../../images/league_images.json";
import {
  capitalizeFirstLetter,
  convertUnixToTime,
  SideNavPage,
} from "../Utils";
import BottomAverts from "../AdvertsComponents/BottomAdvertsBadges";
import {
  MdEditNotifications,
  MdNotificationAdd,
  MdOutlineCheck,
  MdOutlineChecklistRtl,
  MdOutlineContentCopy,
} from "react-icons/md";
import { FaShare } from "react-icons/fa";
import DicplayComments from "../CommentComponents/DicplayComments";
import CollectComments from "../CommentComponents/CommentsInput";
import { io } from "socket.io-client";
import sportyImage from "../../images/sportyBet.png";
import FooterNav from "../SmallScreenNav";
import { RiRefund2Fill } from "react-icons/ri";
import SportIcon from "../ChartComponents/SportIcons";
import { MdOutlineEditNote } from "react-icons/md";
import CopyButton from "../CopyLinkButton";
const API_URL = process.env.REACT_APP_API_URL;
const WEB = process.env.REACT_APP_WEBSITE;
const StorageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const BackSocket = process.env.REACT_APP_SOCKET;
const BackSocketToken = process.env.REACT_APP_SOCKET_TOKEN;

export const Themes = {
  light: {
    background: "light",
    outerTableboarder: "outer-border-light",
    stripeTable: "striped-table-light",
    h6: "_h6-light",
  },
  dark: {
    background: "dark",
    outerTableboarder: "outer-border-dark",
    stripeTable: "striped-table-dark",
    h6: "_h6-dark",
  },
};
export const dataMode = ["all", "current"];
export const Location = () => {
  const [country, setCountry] = useState("Nigeria");
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const longitude = coords.longitude;
      const latitude = coords.latitude;
      if (!globalUser?.user?.curr_country) {
        getCountry(latitude, longitude).then((country) => {
          ////console.log(country);
          setCountry(country);
        });
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]); // added dependencies to make sure effect runs whenever these values change

  if (!country) {
    return null;
  }

  ////console.log(country);
  return country; // used {} to ensure country state value is displayed, not the string 'country'
};

const getCountry = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://api-bdc.net/data/reverse-geocode?latitude=${latitude}&longitude=${longitude}&localityLanguage=en&key=${REACT_APP_BIGDATA}`
    );

    return response?.data?.countryName;
  } catch (error) {
    //console.error(error);
  }
};

export default function DisplayTickets() {
  const [loading, setLoading] = useState(true);
  const { globalUser, updateGlobalUser } = useContext(GlobalUserContext);
  const location = useLocation();
  const userinfo = location.state?.userinfo || {};
  const [isLogedin, setIsLogedin] = useState(false);
  const [issubscribed, setIsSubscribed] = useState(false);
  const [curr_location, setcurr_location] = useState("unknown");
  const [userInfo, setUserInfo] = useState(userinfo);
  const [radioValue, setRadioValue] = useState(globalUser?.user?.states?.theme);
  const [isclickTicket, setisclickTicket] = useState(false);
  const [isclickedShare, setisclickedShare] = useState(false);
  const [collapseOpened, setCollapseOpened] = useState("accordionCollapse1");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMidScreen, setIsMidScreen] = useState(false);
  const [reloadChat, setReloadChat] = useState("false");
  const [inputChat, setInputChat] = useState("");
  const [commentType, setCommentType] = useState("comment");
  const [replyContent, setReplyContent] = useState({});
  const [socketInstance, setSocketInstance] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const navigation = useNavigate();
  const textRef = useRef(null);
  const tic_data = location?.state || null;
  const [useMatches, setUseMatches] = useState({});
  const [useLike, setUseLike] = useState();
  const [useHeart, setUseHeart] = useState();
  const [useViews, setUseviews] = useState();
  const [useType, setuseType] = useState();
  const [useTicketID, setuseTicketID] = useState();
  const [authorProfile, setUserProfile] = useState();
  const [authorBackground, setUserBackground] = useState();
  const [isFollowingTicket, setIsFollowingTicket] = useState(false);
  const [isFollowingTipstar, setIsFollowingTipstar] = useState(false);
  const [useFollow, setUseFollow] = useState();
  const [useFollowAuthor, setUseFollowAuthor] = useState();
  ////console.log(tic_data, location);
  const checkScreenSize = () => {
    setIsMidScreen(window.innerWidth < 1000 && window.innerWidth >= 770);
    setIsSmallScreen(window.innerWidth < 770);
  };

  const user = {
    name: "user",
    firstname: "Guest",
    email:
      String.fromCharCode(
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97
      ) + "@betinfoguest.com",
    page: "indexPage",
    theme: Themes?.light,
    dataMode: "low",
    states: {
      theme: { radioValue },
      dataMode: 0,
    },
  };
  const neededInfo = [
    "firstname",
    "lastname",
    "phonenumber",
    "email",
    "address1",
    "address2",
    "dateofbirth",
    "country",
    "creationDate",
    "updatedDate",
    "subscription",
    "verifyEmail",
    "isadmin",
    "city",
    "auth",
    "Tickets",
    "state",
    "policy",
    "Terms",
    "zip",
    "dataMode",
  ];
  const tempInfo = {};
  neededInfo.forEach((key) => {
    if (userinfo?.firstname) {
      if (userinfo.hasOwnProperty(key)) {
        tempInfo[key] = userinfo[key];
      }
    } else {
      if (user.hasOwnProperty(key)) {
        tempInfo[key] = user[key];
      }
    }
  });
  tempInfo["name"] = "user";
  tempInfo["page"] = "index";
  tempInfo["theme"] = Themes?.light;
  //tempInfo["currCountry"] = Demo()
  const userData = { user: tempInfo };

  useEffect(() => {
    const fetchData = async () => {
      let ckUser;
      if (Object.keys(userinfo).length === 0) {
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log("get");
      } else if (
        Object.keys(globalUser).length === 0 &&
        Object.keys(userinfo).length !== 0
      ) {
        ////console.log("get since global is zero and userinfo is not");
        ckUser = await UpdateLocal({
          action: "get",
          data: { getKeys: ["user"] },
        });
        ////console.log(ckUser);
        if (
          ckUser?.data &&
          ckUser?.data?.user &&
          ckUser?.data?.user?.user?.firstname !== "Guest" &&
          ckUser?.data?.user?.user
        ) {
          ("do nothing");
          ////console.log("i did nothing in duel");
        } else {
          ////console.log("from local is guest using from login");
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
          setIsLogedin(true);
          if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "inactive"
          ) {
            ////console.log("inactive");
            setIsSubscribed(false);
          } else if (
            userData?.user &&
            userData?.user?.subscription &&
            userData?.user?.subscription?.status === "active"
          ) {
            setIsSubscribed(true);
            ////console.log("active");
          } else {
            setIsSubscribed(false);
            ////console.log("nosub");
          }
        }
      } else {
        ////console.log("save");
        if (
          globalUser &&
          globalUser?.user &&
          globalUser?.user?.firstname !== "Guest"
        ) {
          ("do nothing");
          ////console.log("did nothing");
        } else {
          ////console.log("somehow i skipped this,", globalUser);
          ckUser = await UpdateLocal({
            action: "save",
            data: userData,
          });
        }

        setIsLogedin(true);
        if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "inactive"
        ) {
          ////console.log("inactive");
          setIsSubscribed(false);
        } else if (
          userData?.user &&
          userData?.user?.subscription &&
          userData?.user?.subscription?.status === "active"
        ) {
          setIsSubscribed(true);
          ////console.log("active");
        } else {
          setIsSubscribed(false);
          ////console.log("nosub");
        }
      }

      ////console.log(userData);
      if (
        (ckUser && ckUser.result === "saved") ||
        (ckUser && ckUser.result === "retrieved" && ckUser.data !== undefined)
      ) {
        if (ckUser && ckUser.result === "saved") {
          userData["user"]["currCountry"] = curr_location;
          updateGlobalUser(userData);
          ////console.log("wwwasss here in saved to global with user data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "view_ticket",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          setUserInfo(userData);
        } else if (ckUser && ckUser.data && ckUser.data.user) {
          ckUser["data"]["user"]["user"]["currCountry"] = curr_location;

          // *** update login and subs *****
          if (
            (ckUser.data.user &&
              ckUser.data.user?.user?.firstname === "Guest" &&
              ckUser.data.user?.user) ||
            (ckUser.data.user && Object.keys(ckUser.data.user).length === 0)
          ) {
            setIsLogedin(false);
            setIsSubscribed(false);
          } else if (
            ckUser.data.user &&
            ckUser.data.user?.user?.firstname !== "Guest"
          ) {
            if (
              ckUser.data.user &&
              !ckUser.data.user?.user?.subscription &&
              ckUser.data.user?.user
            ) {
              ////console.log(ckUser.data.user);
              setIsLogedin(true);
              setIsSubscribed(false);
            } else {
              if (
                ckUser.data.user &&
                ckUser.data.user?.user?.subscription?.status === "inactive"
              ) {
                setIsLogedin(true);
                setIsSubscribed(false);
              } else {
                setIsLogedin(true);
                setIsSubscribed(true);
              }
            }
          }
          // *** End update login and subs *****
          updateGlobalUser(ckUser.data.user);
          ////console.log("wwwasss here in saved to global with chuser data");
          try {
            axios
              .post(`${API_URL}/active_users_save`, {
                email: ckUser.data.user?.user?.email,
                page: "view_ticket",
              })
              .catch((error) => {
                //throw new Error('Server is unavailable, please try again later');
              });
          } catch (error) {
            ("do noithing");
          }
          userData["user"]["currCountry"] = curr_location;
          setUserInfo(userData);
        } else {
          toast.error("An error occured");
        }
      } else {
        userData["user"]["currCountry"] = curr_location;
        ////console.log(" saved to global user with user data logged out");

        updateGlobalUser(userData);
        setUserInfo(userData);
        UpdateLocal({ action: "save", data: userData });
        //toast.info("You are logged out");
      }
    };

    fetchData();
    if (globalUser && Object.keys(globalUser).length === 0) {
      return;
    }
    ////console.log("save");
    if (
      (globalUser &&
        globalUser?.user?.firstname === "Guest" &&
        globalUser?.user) ||
      (globalUser && Object.keys(globalUser).length === 0)
    ) {
      setIsLogedin(false);
      setIsSubscribed(false);
    } else if (globalUser && globalUser?.user?.firstname !== "Guest") {
      if (globalUser && !globalUser?.user?.subscription && globalUser?.user) {
        ////console.log(globalUser);
        setIsLogedin(true);
        setIsSubscribed(false);
      } else {
        if (
          globalUser &&
          globalUser?.user?.subscription?.status === "inactive"
        ) {
          setIsLogedin(true);
          setIsSubscribed(false);
        } else {
          setIsLogedin(true);
          setIsSubscribed(true);
        }
      }
    }
  }, [curr_location]);
  useEffect(() => {}, [useLike, useHeart]);
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    let queryData = "";
    let params = new URLSearchParams(window.location.search);
    ////console.log(params);
    let type = params.get("type", null);
    let ticketID = params.get("ticketID", null);
    setuseType(() => type);
    setuseTicketID(() => ticketID);

    if (tic_data) {
      queryData = tic_data;
      setuseType(() => tic_data?.type);
      setuseTicketID(() => tic_data?.ticketID);
    } else if (type && ticketID) {
      queryData = {
        type: type,
        ticketID: ticketID,
      };
    } else {
      ////console.log("there is no ticket to display");
    }

    const fetchData = async () => {
      let res = "";
      ////console.log(queryData);
      if (queryData === "") {
        ("");
      } else {
        try {
          res = await axios
            .post(`${API_URL}/get_tickets_remote`, JSON.stringify(queryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          alert(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
      //console.log(res,queryData );
      if (res?.data?.status === "success") {
        setUseMatches(() => res?.data?.data);
        setReplyContent(() => ({
          name: res?.data?.data?.ticket_meta?.name,
          email: res?.data?.data?.ticket_meta?.author,
        }));
        setUseHeart(() => new Set(res?.data?.data?.ticket_meta?.hearts));
        setUseLike(() => new Set(res?.data?.data?.ticket_meta?.likes));
        setUseviews(() => res?.data?.data?.ticket_meta?.views);
        setUseviews(() => new Set(res?.data?.data?.ticket_meta?.views));
        setUseFollow(() => new Set(res?.data?.data?.ticket_meta?.followers));
        setUseFollowAuthor(
          () => new Set(res?.data?.data?.ticket_meta?.authorFollowers)
        );
        if (
          res?.data?.data?.ticket_meta?.authorFollowers?.includes(
            globalUser?.user?.email
          )
        ) {
          setIsFollowingTipstar(true);
        }
        if (
          res?.data?.data?.ticket_meta?.followers?.includes(
            globalUser?.user?.email
          )
        ) {
          setIsFollowingTicket(true);
        }
        const socket2 = io(BackSocket, {
          auth: {
            token: BackSocketToken,
          },
        });

        setSocketInstance(socket2);
        let oldvirewers = new Set();
        let newviews = new Set();
        try {
          if (globalUser?.user?.email) {
            queryData = {
              type: type ?? tic_data?.type,
              ticketID: ticketID ?? tic_data?.ticketID,
              viewer: globalUser?.user?.email,
            };
            oldvirewers.add(globalUser?.user?.email);
          } else {
            queryData = {
              type: type ?? tic_data?.type,
              ticketID: ticketID ?? tic_data?.ticketID,
              viewer: userData?.user?.email,
            };
            oldvirewers.add(userData?.user?.email);
          }
          if (res?.data?.data?.ticket_meta?.views) {
            newviews = new Set(res?.data?.data?.ticket_meta?.views);
            newviews.add(oldvirewers[0]);
            setUseviews(() => newviews);
          } else {
            newviews = oldvirewers;
            setUseviews(() => newviews);
          }
          res = await axios
            .post(`${API_URL}/add_view`, JSON.stringify(queryData), {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .catch((error) => {});
        } catch (error) {
          ////console.log(error.message);
          //Errorlog("error in daily fixtures", error, "fetch search data");
        }
      }
    };
    fetchData();
  }, [globalUser?.user, reloadChat]);

  useEffect(() => {
    ////console.log(useMatches,useMatches?.ticket_meta?.author);
    async function fetchBackground() {
      try {
        const response = await fetch(
          `https://storage.googleapis.com/${StorageBucket}/${useMatches?.ticket_meta?.author}_background`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const base64data = await blob.text(); // Convert the blob to a string
        setUserBackground(base64data);
      } catch (error) {
        //console.error(error);
      }
      try {
        const response2 = await fetch(
          `https://storage.googleapis.com/${StorageBucket}/${useMatches?.ticket_meta?.author}_profile`
        );
        if (!response2.ok) {
          throw new Error(`HTTP error! status: ${response2.status}`);
        }
        const blob2 = await response2.blob();
        const base64data2 = await blob2.text(); // Convert the blob to a string
        setUserProfile(base64data2);
        ////console.log("finalValue");
      } catch (error) {
        //console.error(error);
      }
    }
    fetchBackground();
  }, [useMatches?.ticket_meta?.author]);

  const toggleAccordion = (value) => {
    value !== collapseOpened ? setCollapseOpened(value) : setCollapseOpened("");
  };
  ////console.log(useMatches);
  if (!useMatches && !globalUser) {
    return null;
  }
  const handleCopyClick = () => {
    if (textRef.current) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(useMatches?.booking_code)
          .then(() => alert("Copied to clipboard"))
          .catch((err) => console.error("Could not copy text: ", err));
      } else {
        ////console.log("Clipboard API is not available");
      }
    }
  };
  const handleteamclick = ({ val, team_, league, edit }) => {
    if (edit) {
      const team_info = {
        team: val,
        league: league,
      };
      /* window.open("/team/" + team_ + "?team=" + val + "&league=" + league + "&data=" + useFixture?.league_nav); */
      navigation("/team/" + team_, { state: team_info });
    }
  };

  const handlematchlick = (match_id, team1, team2, seas, edit) => {
    // /////console.log(team1, team2, match_id);
    /* window.open("/match/" + match_id+ "?match_id=" + match_id + "&team1=" + team1 + "&team2=" + team2 + "&seas=" + seas); */
    if (edit) {
      navigation("/match/" + match_id, {
        state: { match_id: match_id, team1: team1, team2: team2, seas: seas },
      });
    }
  };

  const handleLike = async () => {
    let oldlikes = new Set();
    let newlikes = new Set();
    let queryData = {};
    try {
      if (globalUser?.user?.email) {
        queryData = {
          type: useType,
          ticketID: useTicketID,
          liker: globalUser?.user?.email,
        };
        oldlikes.add(globalUser?.user?.email);
      } else {
        queryData = {
          type: useType,
          ticketID: useTicketID,
          liker: userData?.user?.email,
        };
        oldlikes.add(userData?.user?.email);
      }
      if (useLike) {
        newlikes = new Set([...useLike]);
        newlikes.add(oldlikes[0]);
        setUseLike(() => new Set(newlikes));
      } else {
        newlikes = oldlikes;
        setUseLike(() => newlikes);
      }
      const res = await axios
        .post(`${API_URL}/add_like`, JSON.stringify(queryData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});
    } catch (error) {
      ////console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }
  };
  const handleHeart = async () => {
    let oldhearts = new Set();
    let newhearts = new Set();
    let queryData = {};
    try {
      if (globalUser?.user?.email) {
        queryData = {
          type: useType,
          ticketID: useTicketID,
          heartr: globalUser?.user?.email,
        };
        oldhearts.add(globalUser?.user?.email);
      } else {
        queryData = {
          type: useType,
          ticketID: useTicketID,
          heartr: userData?.user?.email,
        };
        oldhearts.add(userData?.user?.email);
      }
      if (useHeart) {
        newhearts = new Set([...useHeart]);
        newhearts.add(oldhearts[0]);
        setUseHeart(() => new Set(newhearts));
      } else {
        newhearts = oldhearts;
        setUseHeart(() => newhearts);
      }
      const res = await axios
        .post(`${API_URL}/add_heart`, JSON.stringify(queryData), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {});
    } catch (error) {
      ////console.log(error.message);
      //Errorlog("error in daily fixtures", error, "fetch search data");
    }
  };
  const handleMessageClick = (data) => {
    const currTime = Math.floor(new Date().getTime() / 1000);
    const partner = {
      time: currTime + 1,
      name: data?.ticket_meta?.name,
      email: data?.ticket_meta?.author,
    };
    navigation("/myMessages", { state: partner });
  };
  ////console.log(userData, useMatches, globalUser);
  const handleEmojiInput = (emoji) => {
    ////console.log(emoji)
    setInputChat((prev) => `${prev}${emoji?.emoji}`);
  };
  const handleFollowTicketClick = () => {
    let ticketType;
    if (useMatches?.ticket_meta?.private_ticket !== "") {
      ticketType = "private";
    } else if (useMatches?.ticket_meta?.followers_only !== "") {
      ticketType = "followers";
    } else {
      ticketType = "public";
    }

    if (socketInstance) {
      socketInstance.emit("followTicket", {
        data: {
          type: ticketType,
          ticketID: useTicketID,
          state: !isFollowingTicket,
        },
        liker: globalUser?.user?.email,
      });
      setIsFollowingTicket((prev) => !prev);
    }
  };
  const handleFollowTipstartClick = () => {
    if (socketInstance) {
      socketInstance.emit("followTipster", {
        data: {
          email: useMatches?.ticket_meta?.author,
          state: !isFollowingTipstar,
        },
        liker: globalUser?.user?.email,
      });
      setIsFollowingTipstar((prev) => !prev);
    }
  };
  const HandleReshare = () => {
    setisclickedShare(() => true);
  };
  const CopyButtonClick = () => {
    const matches = useMatches?.matches;
    const prevglobal = globalUser?.user?.Tickets && { ...globalUser };
    let statuses = prevglobal?.user?.Tickets?.state;
    let activeTicket = "";
    if (statuses && typeof statuses === "object") {
      for (
        let index = 0;
        index < Object.keys(statuses && statuses)?.length;
        index++
      ) {
        const element = Object.keys(statuses && statuses)[index];
        if (statuses?.[element]) {
          activeTicket = element;
        }
      }
    }
    ////console.log(activeTicket,statuses)
    if (activeTicket !== "") {
      const prevMatch = globalUser?.user?.Tickets && [
        ...globalUser?.user?.Tickets?.[activeTicket]?.matches,
      ];
      //console.log(prevMatch, globalUser);
      for (let index = 0; index < matches?.length; index++) {
        const newMatch = matches[index];
        let matchFound = false;
        let foundint = false;
        for (let oldin = 0; oldin < prevMatch?.length; oldin++) {
          const oldMatch = prevMatch?.[oldin];

          if (oldMatch?.match?.id === "") {
            if (
              oldMatch?.options?.bookie_info?.eventId ===
              newMatch?.options?.bookie_info?.eventId
            ) {
              matchFound = true;
              foundint = oldin;
            }
          } else {
            if (oldMatch?.match?.id === newMatch?.match?.id) {
              matchFound = true;
              foundint = oldin;
            }
          }
        }

        if (matchFound && foundint) {
          prevMatch[foundint] = newMatch;
        } else {
          if (newMatch?.options?.status !== "Ended") {
            prevMatch.push(newMatch);
          }
        }
      }
      prevglobal["user"]["Tickets"][activeTicket]["booking_Info"] = {
        bcode: "",
        ShareURL: "",
      };
      prevglobal.user.Tickets[activeTicket].matches = prevMatch;
      updateGlobalUser(() => prevglobal);
      let ckUser = UpdateLocal({
        action: "save",
        data: prevglobal,
      });
    }
  };
  //console.log(useMatches);
  return (
    <div>
    {isclickedShare ? (
          <CopyButton
            type={useType}
            data={useTicketID}
            setisclickedShare={setisclickedShare}
            isclickedShare={isclickedShare}
            setisclickedPreShare={"setisclickedPreShare"}
            header={"Reshare Ticket"}
          />
        ) : null}
      {isOpen && <SideNavPage isOpen={isOpen} setIsOpen={setIsOpen} />}
      <div className="">
        <div
          className="usemaxSpace"
          style={{
            position: "relative",
            overflow: "hidden",
            minHeight: "300px",
          }}
        >
          <div expand="lg" bgColor="light">
            <IndexHeader
              setisclickTicket={setisclickTicket}
              isclickTicket={isclickTicket}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isLogedin={isLogedin}
        issubscribed={issubscribed}
            />
          </div>
          <section className="text-center text-md-start">
            <div
              className="p-5 bg-image"
              style={{
                height: isSmallScreen ? "100px" : "200px",
                backgroundImage: `url(${authorBackground ?? bgimage})`,
                zIndex: "-1",
              }}
            ></div>

            <div
              className="pb-2"
              style={{ backgroundColor: "background-color: hsl(0, 0%, 98%)" }}
            >
              <MDBContainer>
                <MDBRow className="d-flex justify-content-center align-items-center">
                  <MDBCol lg="6" md="8" className="mb-4 mb-md-0 pt-4">
                    <img
                      src={authorProfile ?? care}
                      className="rounded-circle float-none float-md-start me-4 mb-3 link"
                      alt=""
                      style={{
                        width: "168px",
                        marginTop: "-110px",
                        border: "4px solid hsl(0, 0%, 98%)",
                      }}
                      onClick={()=> navigation("/publicProfile", {
                                state: {
                                  publicProfile:
                                  useMatches?.ticket_meta?.publicProfile ?? useMatches?.ticket_meta?.author,
                                }})}
                    />
                    <h1 className="fw-bold link forceblack" onClick={()=> navigation("/publicProfile", {
                                state: {
                                  publicProfile:
                                  useMatches?.ticket_meta?.publicProfile ?? useMatches?.ticket_meta?.author,
                                }})}>{useMatches?.ticket_meta?.name}</h1>
                    <p className="text-muted">
                      {useMatches?.ticket_meta?.phrase}
                    </p>
                  </MDBCol>
                  <MDBCol
                    lg="6"
                    md="8"
                    className="mb-4 mb-md-0 text-center text-lg-end allpad"
                  >
                    {useMatches?.ticket_meta?.author ===
                    globalUser?.user?.email ? null : globalUser?.user?.email ? (
                      <>
                        <MDBBtn
                          rounded
                          color="secondary"
                          active
                          onClick={() => handleMessageClick(useMatches)}
                        >
                          <div style={{ textTransform: "none" }}>Message</div>
                        </MDBBtn>
                        &nbsp;&nbsp;&nbsp;
                        <MDBBtn rounded>
                          <div
                            onClick={() => handleFollowTipstartClick()}
                            style={{ textTransform: "none" }}
                          >
                            {isFollowingTipstar ? "Following" : "Follow Tips"}
                          </div>
                        </MDBBtn>
                      </>
                    ) : (
                      <>
                        <MDBPopconfirm
                          btnClassName="me-1 "
                          placement="top"
                          confirmBtnText={
                            <div
                              onClick={() => navigation("/SignIn")}
                              style={{ textTransform: "none" }}
                            >
                              Login
                            </div>
                          }
                          cancelBtnText={
                            <div style={{ textTransform: "none" }}>Ok</div>
                          }
                          btnChildren={
                            <div style={{ textTransform: "none" }}>Message</div>
                          }
                          color="secondary"
                        >
                          <MDBPopconfirmMessage>
                            You are not logged in.{" "}
                            <a
                              className="link"
                              onClick={() => navigation("/SignIn")}
                            >
                              Login
                            </a>{" "}
                            to send messages?
                          </MDBPopconfirmMessage>
                        </MDBPopconfirm>
                        &nbsp;&nbsp;&nbsp;
                        <MDBPopconfirm
                          btnClassName="me-1 "
                          placement="top"
                          confirmBtnText={
                            <div
                              onClick={() => navigation("/SignIn")}
                              style={{ textTransform: "none" }}
                            >
                              Login
                            </div>
                          }
                          cancelBtnText={
                            <div style={{ textTransform: "none" }}>Ok</div>
                          }
                          btnChildren={
                            <div style={{ textTransform: "none" }}>
                              Follow Tips
                            </div>
                          }
                        >
                          <MDBPopconfirmMessage>
                            You are not logged in.{" "}
                            <a
                              className="link"
                              onClick={() => navigation("/SignIn")}
                            >
                              Login
                            </a>{" "}
                            to follow tipstar now!
                          </MDBPopconfirmMessage>
                        </MDBPopconfirm>
                      </>
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </section>
        </div>
      </div>
      <div style={{ zIndex: 0 }}>
        <MDBContainer>
          <MDBCard className="mainFont usemaxSpace margin-20down">
            <MDBCardHeader className="usemaxSpace">
              <div style={{ width: isSmallScreen ? "100%" : "100%" }}>
                <div className="usemaxSpace center_div">
                  <div className=" center_div_distributed usemaxSpace">
                    <div className="center_div center_sides">
                      <div className="allpad">
                        {capitalizeFirstLetter(
                          useMatches?.ticket_meta?.ticket_name
                        )}
                      </div>
                      <div className="allpad">Ticket</div>
                    </div>
                    <div className="center_div center_sides">
                      <div className="center_div center_sides">
                        <div>Total Odds :</div>&nbsp;
                        <div>
                          {useMatches?.ticket_meta?.t_odds === ""
                            ? "not calculated"
                            : useMatches?.ticket_meta?.t_odds}
                        </div>
                      </div>
                    </div>
                    <div className="center_div center_sides">
                      <div className="center_div_distributed center_sides ">
                        <div className="allpad">
                          {globalUser?.user?.email ? (
                            <>
                              <MDBPopconfirm
                                placement="top"
                                size="sm"
                                btnClassName=" divblueButton forceblack"
                                cancelBtnText={
                                  <div style={{ textTransform: "none" }}>
                                    Cancel
                                  </div>
                                }
                                confirmBtnText={
                                  <div style={{ textTransform: "none" }}>
                                    Yes, Copy
                                  </div>
                                }
                                btnChildren={
                                  <div className="center_div center_sides">
                                    <div style={{ textTransform: "none" }}>
                                      Copy Bet
                                    </div>{" "}
                                    &nbsp;{" "}
                                    <div>
                                      <MdOutlineEditNote size={25} />
                                    </div>
                                    &nbsp;{" "}
                                  </div>
                                }
                                onConfirm={() => CopyButtonClick()}
                              >
                                <MDBPopconfirmMessage>
                                  <div>
                                    <div className="center_div center_sides bold">
                                      {" "}
                                      <SlNote /> &nbsp;<div>Notice!</div>
                                    </div>
                                  </div>
                                  This would add all the active matches on this
                                  ticket to any of your activated tickets. If there
                                  are any duplicate matches, it overwrites the
                                  existing match.
                                </MDBPopconfirmMessage>
                              </MDBPopconfirm>
                            </>
                          ) : (
                            <>
                              <MDBPopconfirm
                                btnClassName="divblueButton forceblack"
                                placement="top"
                                confirmBtnText={
                                  <div
                                    onClick={() => navigation("/SignIn")}
                                    style={{ textTransform: "none" }}
                                  >
                                    Login
                                  </div>
                                }
                                cancelBtnText={
                                  <div style={{ textTransform: "none" }}>
                                    Ok
                                  </div>
                                }
                                btnChildren={
                                  <div className="center_div center_sides">
                                    <div style={{ textTransform: "none" }}>
                                      Copy Bet
                                    </div>{" "}
                                    &nbsp;{" "}
                                    <div>
                                      <MdOutlineEditNote size={25} />
                                    </div>
                                    &nbsp;{" "}
                                  </div>
                                }
                              >
                                <MDBPopconfirmMessage>
                                  You are not logged in! You need a ticket to
                                  copy bet into.{" "}
                                  <a
                                    className="link"
                                    onClick={() => navigation("/SignIn")}
                                  >
                                    Login
                                  </a>{" "}
                                  to activate your ticket now.
                                </MDBPopconfirmMessage>
                              </MDBPopconfirm>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow className="d-flex justify-content-center align-items-center">
                {!isSmallScreen ? (
                  <MDBTable small>
                    <MDBTableHead>
                      <tr className="forceblack">
                        <th scope="col">
                          <div className="center_div"> Time</div>
                        </th>
                        <th scope="col">
                          <div className="center_div"> Status</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">League</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Match</div>
                        </th>
                        <th scope="col">
                          <div className="center_div"> Pick </div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Result </div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Odds </div>
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {useMatches &&
                        useMatches?.matches?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <div className="center_div center_sides">
                                  <SportIcon
                                    sport={
                                      item?.options?.bookie_info?.sport?.name
                                    }
                                  />{" "}
                                  &nbsp;
                                  {isSmallScreen
                                    ? convertUnixToTime(
                                        item?.match?.date_unix,
                                        "time"
                                      )
                                    : convertUnixToTime(
                                        item?.match?.date_unix,
                                        ""
                                      )}
                                </div>
                              </th>
                              <td>
                                <div className="center_div">
                                  {item?.options?.elpaseTime !== "" ? (
                                    <>
                                      <div
                                        className="center_div center_sides nobgbutton buttonBorders-blue forcered"
                                        style={{
                                          backgroundColor: "rgb(238, 220, 160)",
                                        }}
                                      >
                                        &nbsp;
                                        <div>
                                          {item?.options?.status}
                                        </div> -{" "}
                                        <div>
                                          {item?.options?.elpaseTime}
                                          {"'"}
                                        </div>
                                        &nbsp;
                                      </div>
                                    </>
                                  ) : (
                                    <>{item?.match?.status}</>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="center_div">
                                  {League_images?.[
                                    item &&
                                      item?.["match"] &&
                                      item?.["match"]?.["competition_id"]
                                  ]?.["name"] ??
                                    item?.options?.bookie_info?.sport?.category
                                      ?.tournament?.name}
                                </div>
                              </td>
                              <td>
                                <div className="center_div_distributed center_sides">
                                  <div
                                    className="awayTable link forceblack"
                                    onClick={() =>
                                      handleteamclick({
                                        val: item?.home_data["id"],
                                        team_: item?.home_data["cleanName"],
                                        league:
                                          item?.["match"]?.["competition_id"],
                                        edit: item?.options?.canEdit,
                                      })
                                    }
                                  >
                                    <div
                                      className="center_straight center_sides "
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div
                                        className=""
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          wordWrap: "break-word",
                                          width: "100%",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {item?.home_data?.["cleanName"]}&nbsp;
                                      </div>
                                      <div>
                                        {item?.home_data?.["image"] ===
                                        "" ? null : (
                                          <img
                                            src={item?.home_data?.["image"]}
                                            width="20"
                                            height="15"
                                            alt={item?.home_data?.["cleanName"]}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  &nbsp;
                                  <div
                                    className="link forceblack"
                                    onClick={() =>
                                      handlematchlick(
                                        item?.match_id,
                                        item?.home_data?.["id"],
                                        item?.away_data?.["id"],
                                        item?.match?.competition_id,
                                        item?.options?.canEdit
                                      )
                                    }
                                  >
                                    VS
                                  </div>
                                  &nbsp;
                                  <div>
                                    <div
                                      className="awayTable link forceblack"
                                      onClick={() =>
                                        handleteamclick({
                                          val: item?.away_data["id"],
                                          team_: item?.away_data["cleanName"],
                                          league:
                                            item?.["match"]?.["competition_id"],
                                          edit: item?.options?.canEdit,
                                        })
                                      }
                                    >
                                      <div
                                        className="center_straight center_sides "
                                        style={{ maxWidth: "200px" }}
                                      >
                                        <div>
                                          {item?.away_data?.["image"] ===
                                          "" ? null : (
                                            <img
                                              src={item?.away_data?.["image"]}
                                              width="20"
                                              height="15"
                                              alt={
                                                item?.away_data?.["cleanName"]
                                              }
                                            />
                                          )}
                                        </div>
                                        <div
                                          className=""
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordWrap: "break-word",
                                            width: "100%",
                                            overflowWrap: "break-word",
                                          }}
                                        >
                                          {" "}
                                          &nbsp;{item?.away_data?.["cleanName"]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="center_div">
                                  {item?.options?.descptn}
                                </div>
                              </td>
                              <td>
                                <div className=" nobgbutton">
                                  {item?.options?.score !== ""
                                    ? item?.options?.score
                                    : "0-0"}
                                </div>
                              </td>
                              <td>
                                <div className="center_div">
                                  {item?.options?.status === "Ended" ? (
                                    <>
                                      <div>
                                        <div className="center_div center_sides">
                                          <div>
                                            {item?.match?.status === "win" ? (
                                              <MdOutlineCheck
                                                style={{ color: "green" }}
                                              />
                                            ) : item?.match?.status ===
                                              "loss" ? (
                                              <RxCross2
                                                style={{ color: "red" }}
                                              />
                                            ) : (
                                              <RiRefund2Fill
                                                style={{ color: "gray" }}
                                              />
                                            )}
                                          </div>
                                          &nbsp;
                                          <div>{item?.options?.odds}</div>
                                        </div>
                                      </div>
                                    </>
                                  ) : item?.options?.status === "Not start" ? (
                                    <>
                                      <div>{item?.options?.odds}</div>
                                    </>
                                  ) : (
                                    <>
                                      <div>
                                        {" "}
                                        <div className="center_div center_sides">
                                          <div className="Odds_button timerfontwhite">
                                            {" "}
                                            &nbsp; Live' &nbsp;
                                          </div>{" "}
                                          &nbsp;{" "}
                                          <div>{item?.options?.odds}</div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </MDBTableBody>
                  </MDBTable>
                ) : (
                  <MDBTable striped>
                    <MDBTableHead>
                      <tr>
                        <th scope="col">
                          <div className="center_div">Match Info</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">Match</div>
                        </th>
                        <th scope="col">
                          <div className="center_div">options</div>
                        </th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {useMatches &&
                        useMatches?.matches?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row">
                                <div className="center_div center_straight">
                                  <div>
                                    <div className="center_div center_sides">
                                      <SportIcon
                                        sport={
                                          item?.options?.bookie_info?.sport
                                            ?.name
                                        }
                                      />{" "}
                                      &nbsp;
                                      {convertUnixToTime(
                                        item?.match?.date_unix,
                                        "short"
                                      )}
                                    </div>
                                    <div className="center_div">
                                      {
                                        League_images?.[
                                          item &&
                                            item?.["match"] &&
                                            item?.["match"]?.["competition_id"]
                                        ]?.["name"]
                                      }
                                    </div>
                                    <div className="center_div">
                                      {item?.options?.elpaseTime !== "" ? (
                                        <>
                                          <div
                                            className="center_div center_sides nobgbutton buttonBorders-blue forcered"
                                            style={{
                                              backgroundColor:
                                                "rgb(238, 220, 160)",
                                            }}
                                          >
                                            &nbsp;
                                            <div>
                                              {item?.options?.status}
                                            </div> -{" "}
                                            <div>
                                              {item?.options?.elpaseTime}
                                              {"'"}
                                            </div>
                                            &nbsp;
                                          </div>
                                        </>
                                      ) : (
                                        <>{item?.match?.status}</>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </th>

                              <td>
                                <div className="center_div center_straight">
                                  <div
                                    className="awayTable link forceblack"
                                    onClick={() =>
                                      handleteamclick({
                                        val: item?.home_data["id"],
                                        team_: item?.home_data["cleanName"],
                                        league:
                                          item?.["match"]?.["competition_id"],
                                        edit: item?.options?.canEdit,
                                      })
                                    }
                                  >
                                    <div
                                      className="center_straight center_sides "
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <div
                                        className=""
                                        style={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          wordWrap: "break-word",
                                          width: "100%",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {item?.home_data?.["cleanName"]}&nbsp;
                                      </div>
                                      <div>
                                        {item?.home_data?.["image"] ===
                                        "" ? null : (
                                          <img
                                            src={item?.home_data?.["image"]}
                                            width="20"
                                            height="15"
                                            alt={item?.home_data?.["cleanName"]}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="link forceblack"
                                    onClick={() =>
                                      handlematchlick(
                                        item?.match_id,
                                        item?.home_data?.["id"],
                                        item?.away_data?.["id"],
                                        item?.match?.competition_id,
                                        item?.options?.canEdit
                                      )
                                    }
                                  >
                                    VS
                                  </div>
                                  <div>
                                    <div
                                      className="awayTable link forceblack"
                                      onClick={() =>
                                        handleteamclick({
                                          val: item?.away_data["id"],
                                          team_: item?.away_data["cleanName"],
                                          league:
                                            item?.["match"]?.["competition_id"],
                                          edit: item?.options?.canEdit,
                                        })
                                      }
                                    >
                                      <div
                                        className="center_straight center_sides "
                                        style={{ maxWidth: "200px" }}
                                      >
                                        <div>
                                          {item?.away_data?.["image"] ===
                                          "" ? null : (
                                            <img
                                              src={item?.away_data?.["image"]}
                                              width="20"
                                              height="15"
                                              alt={
                                                item?.away_data?.["cleanName"]
                                              }
                                            />
                                          )}
                                        </div>
                                        <div
                                          className=""
                                          style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            wordWrap: "break-word",
                                            width: "100%",
                                            overflowWrap: "break-word",
                                          }}
                                        >
                                          {" "}
                                          &nbsp;{item?.away_data?.["cleanName"]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="center_div center_straight">
                                  <div className="center_div">
                                    {item?.options?.descptn}
                                  </div>
                                  <div className=" nobgbutton">
                                    &nbsp;
                                    {item?.options?.score !== ""
                                      ? item?.options?.score
                                      : "0-0"}
                                    &nbsp;
                                  </div>
                                  <div className="center_div">
                                    {item?.options?.status === "Ended" ? (
                                      <>
                                        <div>
                                          <div className="center_div center_sides">
                                            <div>
                                              {item?.match?.status === "win" ? (
                                                <MdOutlineCheck
                                                  style={{ color: "green" }}
                                                />
                                              ) : item?.match?.status ===
                                                "loss" ? (
                                                <RxCross2
                                                  style={{ color: "red" }}
                                                />
                                              ) : (
                                                <RiRefund2Fill
                                                  style={{ color: "gray" }}
                                                />
                                              )}
                                            </div>
                                            &nbsp;
                                            <div>{item?.options?.odds}</div>
                                          </div>
                                        </div>
                                      </>
                                    ) : item?.options?.status ===
                                      "Not start" ? (
                                      <>
                                        <div>{item?.options?.odds}</div>
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          {" "}
                                          <div className="center_div center_sides">
                                            <div className="Odds_button timerfontwhite">
                                              {" "}
                                              &nbsp; Live' &nbsp;
                                            </div>{" "}
                                            &nbsp;{" "}
                                            <div>{item?.options?.odds}</div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </MDBTableBody>
                  </MDBTable>
                )}
              </MDBRow>
              <MDBRow>
                {useMatches?.ticket_meta?.booking_code && (
                  <div className="center_div bold center_sides">
                    <div className="center_div  center_sides ">
                      <div className="allpad">{isSmallScreen ? "":"Bookie :"}</div>
                      <div className="nobgbutton buttonBorders-blue allpad-2">
                        {useMatches?.ticket_meta?.bookie_name ===
                        "SportyBet" ? (
                          <div>
                            <img
                              className=""
                              src={sportyImage}
                              alt={useMatches?.bookie_name}
                              width="60"
                              height="25"
                            />
                          </div>
                        ) : (
                          <div>?</div>
                        )}
                      </div>
                    </div>

                    <div className="center_div bold center_sides ">
                      <div className="allpad">{isSmallScreen ? "Code :":"Booking Code :"}</div>
                      <div
                        className="allpad"
                        onClick={() =>
                          (window.location.href =
                            useMatches?.ticket_meta?.ShareURL)
                        }
                      >
                        <div className="inactiveButton allpad-2 buttonBorders-blue link forceblack">
                          {useMatches?.ticket_meta?.booking_code}
                        </div>
                      </div>

                      <div
                        ref={textRef}
                        onClick={() => {
                          handleCopyClick();
                        }}
                        className="allpad link "
                      >
                        <MdOutlineContentCopy size={25} />{" "}
                      </div>
                    </div>
                  </div>
                )}
              </MDBRow>
              <MDBRow>
                {useMatches?.ticket_meta?.booking_code && (
                  <div className="center_div bold center_sides">
                    <div className="center_div  center_sides ">
                      <div className="allpad">Stake :</div>
                      <div className="nobgbutton buttonBorders-blue allpad-2">
                        {"$ " + useMatches?.ticket_meta?.ticketStake}
                      </div>
                    </div>
                    &nbsp; &nbsp;
                    <div className="center_div bold center_sides ">
                      <div className="allpad"> { useMatches?.ticket_meta?.status  === "pending" ? "Potential Retun :" : useMatches?.ticket_meta?.status === "lost"? "Lost :" : "Won :"}</div>
                      <div
                        
                         className={`${ useMatches?.ticket_meta?.status  === "pending" ? "" : useMatches?.ticket_meta?.status === "lost"? "redlabel" : "greenlabel"}nobgbutton buttonBorders-blue allpad-2`}
                      >
                        {" $ "}{useMatches?.ticket_meta?.status  === "pending" ? useMatches?.ticket_meta?.ticketPotWin : useMatches?.ticket_meta?.status === "lost" ? useMatches?.ticket_meta?.ticketStake : useMatches?.ticket_meta?.ticketPotWin - parseFloat(useMatches?.ticket_meta?.ticketStake)}
                      </div>

                      
                    </div>
                  </div>
                )}
              </MDBRow>
            </MDBCardBody>
            <MDBCardFooter className="text-muted mainFont">
              <div>
                <div className="center_div_distributed center_sides ">
                  <div
                    className={
                      isSmallScreen
                        ? "flex center_straight marginright-10"
                        : "center_div marginright-10"
                    }
                  >
                    <div
                      className={isSmallScreen ? "center_div" : "marginright-5"}
                    >
                      {useLike?.size}
                    </div>{" "}
                    <div>likes</div>
                  </div>
                  <div
                    className={
                      isSmallScreen
                        ? "flex center_straight marginright-10"
                        : "center_div marginright-10"
                    }
                  >
                    <div
                      className={isSmallScreen ? "center_div" : "marginright-5"}
                    >
                      {useHeart?.size}{" "}
                    </div>{" "}
                    <div>hearts</div>
                  </div>
                  <div
                    className={
                      isSmallScreen
                        ? "flex center_straight marginright-10"
                        : "center_div marginright-10"
                    }
                  >
                    <div
                      className={isSmallScreen ? "center_div" : "marginright-5"}
                    >
                      {useViews?.size}{" "}
                    </div>
                    <div>views</div>
                  </div>
                  <div
                    className={
                      isSmallScreen
                        ? "flex center_straight marginright-10"
                        : "center_div marginright-10"
                    }
                  >
                    <div
                      className={isSmallScreen ? "center_div" : "marginright-5"}
                    >
                      {useMatches?.ticket_meta?.comments == 0
                        ? "no"
                        : useMatches?.ticket_meta?.comments}{" "}
                    </div>
                    <div>
                      {useMatches?.ticket_meta?.comments > 1
                        ? " comments"
                        : "comment"}
                    </div>
                  </div>
                  <div
                    className={
                      isSmallScreen
                        ? "flex center_straight marginright-10"
                        : "center_div marginright-10"
                    }
                  >
                    <div
                      className={isSmallScreen ? "center_div" : "marginright-5"}
                    >
                      {useFollow?.size}{" "}
                    </div>
                    <div>{useFollow?.size > 1 ? "tailers" : "tailer"}</div>
                  </div>
                </div>

                <div className="horizontal_line_black allpad" />
                <div className="center_div_distributed center_sides">
                  <div
                    className="center_div center_straight  allpad link forceblack"
                    onClick={() => handleLike()}
                  >
                    <div>
                      <FiThumbsUp style={{ color: "blue" }} />
                    </div>{" "}
                    <div>{" Like"}</div>
                  </div>{" "}
                  <div
                    className="center_div center_straight  allpad link forceblack"
                    onClick={() => handleHeart()}
                  >
                    <div>
                      <IoHeart style={{ color: "red" }} />
                    </div>
                    <div>{" Heart"}</div>
                  </div>
                  <div
                    className="center_div center_straight  allpad link forceblack"
                    onClick={() => handleFollowTicketClick()}
                  >
                    {isFollowingTicket ? (
                      <MdEditNotifications
                        size={30}
                        style={{ color: "orange" }}
                      />
                    ) : (
                      <MdNotificationAdd size={30} style={{ color: "green" }} />
                    )}{" "}
                    {isFollowingTicket ? "Tailing" : "Tail"}
                  </div>{" "}
                  <div
                    className="center_div center_straight  allpad link forceblack"
                    onClick={() => HandleReshare()}
                  >
                    <FaShare size={20} style={{ color: "skyblue" }} />{" "}
                    {"Share "}
                  </div>{" "}
                </div>
              </div>
            </MDBCardFooter>
          </MDBCard>
          <MDBCard
            alignment="center"
            className="usemaxSpace margin-50down"
            style={{ height: isSmallScreen ? "500px" : "650px" }}
          >
            <MDBCardHeader className="usemaxSpace">
              <div style={{ width: isSmallScreen ? "100%" : "50%" }}>
                <div className="usemaxSpace center_div">
                  <div className=" center_div_distributed usemaxSpace">
                    <div className="center_div center_sides">
                      <div className="allpad">Comments Section</div>
                    </div>
                    {useMatches?.ticket_meta?.comments ? (
                      <div className="center_div center_sides">
                        <div className="center_div center_sides">
                          <div>{useMatches?.ticket_meta?.comments ?? "No"}</div>
                          &nbsp;
                          <div>
                            {"Comment"}
                            {useMatches?.ticket_meta?.comments > 1 ? "s" : ""}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="center_div center_sides">
                        <div className="center_div center_sides">
                          <div>Be the first to comment !!</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </MDBCardHeader>
            <MDBCardBody
              className="overflow-scroll noscrolbar "
              style={{ position: "relative", overflow: "hidden" }}
            >
              <DicplayComments
                socketInstance={socketInstance}
                originalOwner={{
                  name: useMatches?.ticket_meta?.name,
                  email: useMatches?.ticket_meta?.author,
                }}
                userData={globalUser}
                targetObject={useTicketID}
                targetDb={"TicketComments"}
                setCommentType={setCommentType}
                setReplyContent={setReplyContent}
                reloadChat={reloadChat}
              />
            </MDBCardBody>
            <MDBCardFooter>
              <CollectComments
                socketInstance={socketInstance}
                owner={replyContent}
                parent={{
                  site: "/ShowTickets",
                  object: useTicketID,
                  type: useType,
                }}
                paticipant={{
                  name:
                    globalUser?.user?.tipstarDetails?.tipstarName ??
                    globalUser?.user?.firstname,
                  email: globalUser?.user?.email,
                }}
                userData={globalUser}
                targetPage={"/ShowTickets"}
                targetObject={useTicketID}
                commentType={commentType}
                replyContent={replyContent}
                setReloadChat={setReloadChat}
                setCommentType={setCommentType}
              />
            </MDBCardFooter>
          </MDBCard>
          <div className="center_div margin-50down">
            <BottomAverts />
          </div>
        </MDBContainer>
      </div>
      <FooterAll />
      <FooterNav />
    </div>
  );
}
